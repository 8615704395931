import {ORDER_HISTORY} from "./constant";

const orderHistoryCallApi = (type , data , external={})=>{
    
    switch(type){
        case ORDER_HISTORY:
            return {
                type:type,
                data:data,
                external:external
            }
            default:
                return{
                    type:type
                }
    }
        
}
export default orderHistoryCallApi