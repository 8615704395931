import { ACTIVE_SUBSCRIPTIONS, ACTIVE_SUBSCRIPTIONS_ERROR, ACTIVE_SUBSCRIPTIONS_SUCCESS } from "./constant"


const activeSubscriptionsReducer = (state={},action)=>{

    switch(action.type){
        case ACTIVE_SUBSCRIPTIONS:{
            return {
                ...state,
                loading:true,
                error:''
            };
        }
        case ACTIVE_SUBSCRIPTIONS_ERROR:{
            return {
                ...state,
                loading:false,
                type:ACTIVE_SUBSCRIPTIONS_ERROR,
                error:action.payload
            };
        }
        case ACTIVE_SUBSCRIPTIONS_SUCCESS:{
            return {
                ...state,
                type:ACTIVE_SUBSCRIPTIONS_SUCCESS,
                loading:false,
                payload:action.payload,
                error:''
            };
        }
        default :
        return state;
    }
}
export default activeSubscriptionsReducer