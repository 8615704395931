import { COMPANY_REVIEWS, COMPANY_REVIEWS_ERROR, COMPANY_REVIEWS_SUCCESS } from "./constant"


const companyReviewsReducer = (state={},action)=>{

    switch(action.type){
        case COMPANY_REVIEWS:{
            return {
                ...state,
                loading:true,
                error:''
            };
        }
        case COMPANY_REVIEWS_ERROR:{
            return {
                ...state,
                loading:false,
                type:COMPANY_REVIEWS_ERROR,
                error:action.payload
            };
        }
        case COMPANY_REVIEWS_SUCCESS:{
            return {
                ...state,
                type:COMPANY_REVIEWS_SUCCESS,
                loading:false,
                payload:action.payload,
                error:''
            };
        }
        default :
        return state;
    }
}
export default companyReviewsReducer