import {
    SIGN_UP,
    SIGN_UP_ERROR,
    SIGN_UP_SUCESS,
} from "./constant";

const signUpReducer = (state = {}, action) => {
    switch (action.type) {
        case SIGN_UP:
            return { ...state, loading: true, error: '' };
        case SIGN_UP_ERROR:
            return { ...state, type: SIGN_UP_ERROR, loading: false, error: action.payload };
        case SIGN_UP_SUCESS:
            return { ...state, type: SIGN_UP_SUCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default signUpReducer;