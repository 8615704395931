import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import "pages/ProductDetails/ProductDetails.scss";
import { Box } from '@mui/system';
import Ptext from 'components/Ptext/Ptext';
import BasicButton from 'components/Buttons/BasicButton/BasicButton';
import H1text from 'components/H1text/H1text';
import { toast } from "react-toastify"
import TabSwitch from 'components/TabSwitch/TabSwitch';
import CustomStepper from 'components/CustomStepper/CustomStepper';
import BasicInput from 'components/Inputs/BasicInput/BasicInput';
import appleBrand from 'assets/images/appleBrand.png';
import AddIcon from '@mui/icons-material/Add';
import PlaceholderInput from 'components/Inputs/PlaceholderInput/PlaceholderInput';
import CustomCheckbox from 'components/Inputs/CustomCheckbox/CustomCheckbox';
import { deleteMethodWithToken, getMethod, getMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { formatCardNumber, formatCvv, formatExpiry, getToken, isValidCardNumber, isValidCVV, isValidExpiryDate, setToken, truncateTextByChars, validateEmail, validatePhoneNumber } from 'services/common';
import { useDispatch, useSelector } from 'react-redux';
import {
    Elements, useElements, useStripe, CardNumberElement,
    CardCvcElement, CardExpiryElement, CardElement
} from '@stripe/react-stripe-js';
import paymentIntentCallAPI from 'store/paymentIntent/action';
import { PAYMENT_INTENT } from 'store/paymentIntent/constant';
import checkoutCallApi from 'store/checkout/action';
import { CHECKOUT } from 'store/checkout/constant';
import { commonCallApi } from 'store/common/action';
import { COMMON_DETAILS } from 'store/common/constant';
import { useNavigate } from 'react-router-dom';
import cartCallApi from 'store/cart/action';
import { CART } from 'store/cart/constant';
import { CircularProgress } from "@mui/material";
import styled from 'styled-components';
import countriesCallApi from 'store/Countries/action';
import { COUNTRIES } from 'store/Countries/constant';
// import PhoneInput from 'react-phone-input-2';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { ANONYMOUS } from 'store/anonymous/constant';
import anonymousCallApi from 'store/anonymous/action';
import { USER } from 'store/user/constant';
import userCallApi from 'store/user/action';
import CloseIcon from '@mui/icons-material/Close';

const WrapperDiv = styled.div`
.StripeElement{
    height:48px;
    display:flex!important;
    align-items:center!important;
    border-radius: 12px!important;
    background: transparent!important;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family:'Manrope';
}
.__PrivateStripeElement{
    width:100% !important;
}
`



const InnerContent = ({ handleClose, productDetails, type, setIsModalActive2 }) => {
    const stripe = useStripe();
    console.log("objectddd",productDetails)
    const elements = useElements();
    const [isModalActive, setIsModalActive] = useState(false);
    const [switchTab, setSwitchTab] = useState(1);
    const [submitLoader, setSubmitLoader] = useState(false);
    const tranferData = (value) => {
        setSwitchTab(value)
    }
    const steps = ['Contact Info', 'Delivery', 'Payment'];

    const [currentStep, setCurrentStep] = useState(0);
    const userMe = useSelector(state => state?.userReducer?.payload?.data);
    console.log("werwer", userMe?.stripe_ID)
    useEffect(() => {
        if (userMe?.is_anonymous) {

        } else {
            setFirstName(userMe?.first_name)
            setLastName(userMe?.last_name)
            if (userMe?.email) {
                setEmailValue(userMe?.email)
            } else {
                setEmailValue("")
            }
        }


        // getMeProfile();
    }, [])


    useEffect(() => {
        if (!isModalActive) {
            setCurrentStep(0);
        }
    }, [isModalActive]);

    const handleNext = (step) => {
        if (currentStep == 0) {
            if (validateEmail(email) && validatePhoneNumber(phone)) {


                if (getToken()) {
                    setCurrentStep(prevStep => prevStep + 1);
                } else {
                    anonymousWork();
                }
            } else {
                toast.error("Fill all mandatory fields correctly");
            }
        }
        if (currentStep == 1) {
            // if (currentStep < steps.length - 1) {
            // }
            if (title !== '' && state !== '' && postalCode !== '' && city !== '' && country !== '' && firstName !== '' && lastName !== '' && address !== '') {
                setCurrentStep(prevStep => prevStep + 1);
            }
            else {
                toast.error("Fill all mandatory fields correctly");
            }
        }
    };

    const handleBack = () => {
        if (currentStep > 0) {
            setCurrentStep(prevStep => prevStep - 1);

        } else {
            handleClose();
        }

        if (currentStep == 1) {
            if (userMe?.is_anonymous) {
                localStorage.removeItem("token")
            }
        }
    };
    const tabs = [
        {
            id: 1,
            type: 'Buy'
        },
        {
            id: 2,
            type: 'Rent'
        },
    ]

    const addMore = () => {
        setCurrentStep('addMore')
    }

    const [value, setValue] = useState('');
    const [email, setEmailValue] = useState('');
    const [phone, setPhoneValue] = useState('');

    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [state, setState] = useState('');
    const [firstName, setFirstName] = useState('');
    const [title, setTitle] = useState('');
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');

    const [cardNumber, setCardNumber] = useState('');
    const [expiry, setExpiry] = useState('');
    const [cvv, setCvv] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const countryData = useSelector(state => state?.countriesReducer?.payload?.data);

    const [titleList, setTitleList] = useState(
        [
            {
                slug: 'Mr',
                title: 'Mr'
            },
            {
                slug: 'Mrs',
                title: 'Mrs'
            },
            {
                slug: 'Miss',
                title: 'Miss'
            },
            {
                slug: 'Ms',
                title: 'Ms'
            },
            {
                // id: 5,
                slug: 'Dr',
                title: 'Dr'
            },
        ]
    );
    // const abc =()=>{
    //     let input = event.target.value;
    //     let formatted = input;
    //     let unformatted = input.replace(/\D/g, '');

    //     if (type === 'cardNumber') {
    //       formatted = formatCardNumber(input);
    //       unformatted = formatted.replace(/\s/g, '');
    //     } else if (type === 'expiry') {
    //       formatted = formatExpiry(input);
    //       unformatted = formatted.replace('/', '');
    //     } else if (type === 'cvv') {
    //       unformatted = input.replace(/\D/g, '').slice(0, 4);
    //       formatted = unformatted;
    //     }else if (type === 'tel') {
    //       // Allow only numbers for telephone input
    //       unformatted = input.replace(/\D/g, ''); // Remove non-digit characters
    //       formatted = unformatted; // You can set formatted as unformatted if no additional formatting is needed
    //     }
    //     else{
    //       unformatted = input
    //     }

    //     setValue(formatted);

    //     // Pass the unformatted value to the parent component
    //     if (onChange) {
    //       onChange({
    //         target: {
    //           name: name || label,
    //           value: unformatted,
    //         },
    //       });
    //     }
    // }

    // const formatCardNumber = (value) => {
    //     // Remove all non-digit characters
    //     const digits = value.replace(/\D/g, '').slice(0, 16);
    //     // Insert space after every 4 digits
    //     const formatted = digits.replace(/(.{4})/g, '$1 ').trim();
    //     return formatted;
    //   };

    const [isCardNumberValid, setIsCardNumberValid] = useState(null)
    const [isExpiryValid, setIsExpiryValid] = useState(null)
    const [isCvvValid, setIsCvvValid] = useState(null)
    const handleCardNumberChange = (e) => {
        setIsCardNumberValid(e.complete ? "valid" : "invalid");
        // const formatted = formatCardNumber(e.target.value);
        // setCardNumber(formatted);
    };

    const handleExpiryChange = (e) => {
        setIsExpiryValid(e.complete ? "valid" : "invalid");
        // const formatted = formatExpiry(e.target.value);
        // setExpiry(formatted);
    };

    const handleCvvChange = (e) => {
        setIsCvvValid(e.complete ? "valid" : "invalid");
        // const formatted = formatCvv(e.target.value);
        // setCvv(formatted);
    };

    const handleCheckboxChange = (e) => {
        setRememberMe(e.target.checked);
    };

    const handleSubmit = () => {
        if (isCardNumberValid == "valid" && isExpiryValid == "valid" && isCvvValid == "valid") {
            handleCart();
            handleToken();
        }
    };
    const handleCountries = () => {
        dispatch(countriesCallApi(COUNTRIES, {}, { run: true, handleFunction: (res) => handleCountriesSuccess(res) }))
    }
    const handleCountriesSuccess = (res) => {
        // setCountry(res?.data)
    }
    // strip work =======
    const basketId = useSelector(state => state?.userReducer?.payload?.data?.basket_id);
    console.log("objectcv", basketId)
    // `ecom/baskets/${basketId}/lines`
    // localStorage.getItem('cartAPI')

    const handleCart = (res) => {
        dispatch(cartCallApi(CART, {}, {
            url: res?.data?.basket_id === null ? `ecom/baskets/` : `ecom/baskets/${localStorage.getItem("basketId")}/lines`,
            run: true,
            handleFunction: (res) => {
                handleCartCount(res);
                // dispatch(userCallApi(USER, {}, {
                //     run: true,
                //     handleFunction: (res) => {}
                // }))
            }
        }))
    };
    const handleCartCount = (response) => {
        dispatch(commonCallApi(COMMON_DETAILS, { cart: response?.data?.[0]?.total_quantity }, { run: true }))
        // setCartArray(response.data)
        setValue(response?.data?.quantity)
        // response.data?.length > 0 && response.data.map((data) => (
        //     handleProductDetail(data?.product.split('api/v1/')[1])
        // ))

        let amount = 0;
        response.data?.length > 0 && response.data.map((data) => {
            return (

                amount = amount + Number(data?.price_incl_tax)
            )

        });

        localStorage.setItem("totalAmount", amount)


    }

    const dispatch = useDispatch();
    const customerId = useSelector(state => state?.userReducer?.payload?.data?.stripe_ID);
    const [captureId, setCaptureId] = useState('')
    const [countryUrl, setCountryUrl] = useState('');
    const [countryCode, setCountryCode] = useState('');

    const handleToken = async () => {
        setSubmitLoader(true);

        if (!stripe || !elements) {
            return;
        }

        // setIsProcessing(true);


        const { error, token } = await stripe.createToken(elements.getElement(CardNumberElement));

        // setIsProcessing(false);

        if (error) {
            toast.error(error.message);
            setSubmitLoader(false);
        } else if (token) {
            await handlePaymentMethodCreated(token?.id, userMe?.stripe_ID, token?.card?.id);
        }
    };
    const handlePaymentMethodCreated = async (cardId, customerId, cardID) => {

        const secretKey = 'sk_test_51NiXijSGks82zzGkgFRQCXbhU9IEGBPo5VcIGJj9UuC9U9muXv1pLwZA8iGn0LrY8NiJULToMdUlZOkPA3qtpPwg00F6TULlzv';
        const createCard = `https://api.stripe.com/v1/customers/${customerId}/sources`;

        const attachOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${secretKey}`,
            },
            body: new URLSearchParams({ source: cardId }),
        };

        try {
            const attachResponse = await fetch(createCard, attachOptions);

            if (!attachResponse.ok) {
                const attachErrorData = await attachResponse.json();
                return;
            }
            else {
                const attachData = await attachResponse.json();
                dispatch(paymentIntentCallAPI(PAYMENT_INTENT, {}, {
                    run: true,
                    handleFunction: (res) => handleConfirm(res, secretKey, cardID)
                }))

            }


        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleConfirm = async (res, key, cardID) => {
        const id = res?.data?.stripe_ref?.id;
        setCaptureId(res?.data?.stripe_ref?.id)

        const confirmAPI = `https://api.stripe.com/v1/payment_intents/${id}/confirm?return_url=https://docs.stripe.com/api/payment_methods/update`;

        const attachOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${key}`,
            },
            body: new URLSearchParams({ payment_method: cardID }),
        };
        try {
            const attachResponse = await fetch(confirmAPI, attachOptions);

            if (!attachResponse.ok) {
                const attachErrorData = await attachResponse.json();
                return;
            }
            else {
                const attachData = await attachResponse.json();
                handleSubmit2(attachData?.client_secret)
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
    const handleSubmit2 = async (clientSecret) => {
        const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    name: firstName,
                    address: {
                        line1: address,
                        city: city,
                        state: 'state',
                        postal_code: 250001,
                        country: 'IN'
                    }
                }
            }
        },

        );
        if (error) {
            console.error('Payment failed:', error);
            setSubmitLoader(false);

        } else if (paymentIntent.status === 'requires_capture') {
            handleCheckout()

        } else if (paymentIntent.status === 'requires_action' || paymentIntent.status === 'requires_source_action') {

            console.log('Additional action required');

        } else {
            console.log('Payment status:', paymentIntent.status);

        }

    }
    const handleCountry = (e) => {
        const selectedCountry = countryData.find(
            (country) => country.printable_name === e.target.value
        );
        setCountry(e.target.value);
        setCountryUrl(selectedCountry.url)
        // const selectedCountryData = country.find(data => data.url === e.target.value);
        setCountryCode(selectedCountry.iso_3166_1_a3.substring(0, 2))

    }
    const handleTitle = (e) => {
        setTitle(e.target.value);
    }
    const basket = useSelector(state => state?.cartReducer?.payload?.data);
    // Number(localStorage.getItem('totalAmount')),
    console.log("object56", basket)
    const handleCheckout = async () => {
        const amount = localStorage.getItem('totalAmount')
        // basket?.[0]?.basket
        let body = {
            "basket": "https://metroride-api.theclientdemos.com/api/v1/ecom/baskets/" + localStorage.getItem("basketId") + "/",
            // "product":productData[0]?.product,
            "guest_email": email,
            "total": Number(amount),
            "shipping_charge": {
                "currency": "USD",
                "excl_tax": "0.0",
                "tax": "0.0"
            },
            "shipping_method_code": "free-shipping",
            "shipping_address": {
                "country": countryUrl,
                "first_name": firstName,
                "last_name": lastName,
                "line1": address,
                "line2": "",
                "line3": "",
                "line4": "",
                "notes": `Hi ${firstName} it's your checkout note information `,
                "phone_number":  phone,
                "postcode": postalCode,
                "state": state,
                "title": title
            },
            "billing_address": {
                "country": countryUrl,
                "first_name": firstName,
                "last_name": lastName,
                "line1": address,
                "line2": "",
                "line3": "",
                "line4": "",
                "notes": `Hi ${firstName} it's your checkout note information `,
                "phone_number":  phone,
                "postcode": postalCode,
                "state": state,
                "title": title
            }
        }
        dispatch(checkoutCallApi(CHECKOUT, body, {
            run: true,
            handleFunction: (res) => handleSuccess(res),
            handleError: (res) => {
                // if(res.status=="406"){
                //     setIsModalActive(false)
                // }
                if (userMe?.is_anonymous) {
                    localStorage.removeItem("token");
                }
                toast.error(res?.data?.shipping_address?.phone_number[0]);
                setSubmitLoader(false);
                setIsModalActive2(false);
                console.log(res?.data?.shipping_address?.phone_number);

            }
        }))
    }
    const navigate = useNavigate();
    const handleSuccess = (res) => {
        dispatch(commonCallApi(COMMON_DETAILS, {},))
        toast.success("Payment successful! Thank you for your purchase.")
        setSubmitLoader(false);
        handleClose();
        if (userMe?.is_anonymous) {
            localStorage.removeItem("token");
        }
        // navigate('/')
        // dispatch(isDrawer(IS_DRAWER, false))

    }
    const cartSelector = useSelector(state => state.commonReducer?.cart?.cart);
    const [cartItem, setCartItem] = useState(cartSelector ?? 0);
    const handleClearCart = async (data, quantity, api) => {
        console.log("object3e3e", data)
        await deleteMethodWithToken(api).then((response) => {
            if (response?.status === 200 || response?.status === 204) {
                if (cartItem < 0) {
                    dispatch(commonCallApi(COMMON_DETAILS, { 'cart': 0 }, { run: true }));

                }
                else {
                    dispatch(commonCallApi(COMMON_DETAILS, { 'cart': cartItem - quantity }, { run: true }));
                }
                handleCart(localStorage.getItem('cartAPI'))
            }
        })
    }
    // strip work =======

    const [Models, setModels] = useState([]);
    const [product, setProduct] = useState([]);
    const [filteredModels, setFilteredModels] = useState([]);

    // Fetch models on component mount
    useEffect(() => {
        handleModels();
        handleCountries();
    }, []);

    useEffect(() => {
        if (product.length > 0 && Models.length > 0) {
            // Filter models based on selected products
            // setFilteredModels(Models.filter(model => product.includes(model?.id)));
        } else {
            // Reset filteredModels if no products are selected
            setFilteredModels([productDetails]);
            setProduct([productDetails?.id])
        }
    }, [product, Models]);

    const handleModels = async () => {
        const apiUrl = `${url.buy}?catagory=${type == 'bike' ? 'bike' : 'scooter'}&paginate=false&page_limit=0`;
        let response;
        // Fetching models based on token
        response = localStorage.getItem("token")
            ? await getMethodWithToken(apiUrl)
            : await getMethod(apiUrl);

        if (response?.status === 200 || response?.status === 201) {
            setModels(response.data);
        } else {
            console.error("Failed to fetch models:", response?.statusText);
        }
    };

    const handlePhoneNumber = (e) => {
        console.log("nmbv",e)
        setPhoneValue(e)
    }

    const anonymousData = useSelector((state) => state?.anonymousReducer?.payload?.data);
    const anonymousDataloader = useSelector((state) => state?.anonymousReducer?.loading);
    const getMeProfile = () => {
        dispatch(userCallApi(USER, {}, {
            run: true,
            handleFunction: (res) => { }
        }))
    }
    const anonymousWork = () => {
        console.log("objectff", "sdfsdfsdfsdf")
        // anonymousCallApi
        let body = { "email": email, "phone_number": phone }
        dispatch(anonymousCallApi(ANONYMOUS, body, {
            run: true,
            handleFunction: (res) => {
                setToken(res?.data?.token);
                console.log("nmnm", res);
                getMeProfile();
                setCurrentStep(prevStep => prevStep + 1);
            },
            toast: true,
            handleToastError: (err) => {
                // console.log("asda",err?.data?.message)
                toast.error(err?.data?.message ? err?.data?.message : "Something is wrong");
            }
        }));
        // localStorage.setItem("token", anonymousData.token)

    }

    useEffect(() => {
        // ComponentDidMount logic here
        console.log("Component mounted");

        // Cleanup logic
        return () => {
            console.log("Component unmounted");
            if (userMe?.is_anonymous) {
                localStorage.removeItem("token");
            }
        };
    }, []);
    console.log("objecccccvt", filteredModels)
    return (
        <WrapperDiv className='ProductDetails'>
            {
                currentStep === 'addMore' ?
                    <>
                        <Box display='flex' flexWrap={'wrap'} justifyContent='space-between'>
                            <H1text size='30' lineHeight='48' fontWeight='600' marginTop='0'>Add more</H1text>
                            <TabSwitch width={'124px'} tabs={tabs} tranferData={tranferData} />
                        </Box>
                        <PlaceholderInput
                            multiSelect={true}
                            label={"Product"}
                            placeholder={"Product"}
                            data={Models}
                            value={product ? product : []}
                            name={"type"}
                            onChange={(e) => setProduct(e?.target?.value)}
                            style={{ marginTop: '20px' }}
                            error={true}
                        />
                        <Row style={{ marginTop: '16px' }} gap={2}>
                            <Col xs={12}>
                                {/* <Box height={'248px'} backgroundColor={'#fafafa'} borderRadius='12px'>

                                </Box> */}
                                {filteredModels?.length > 0 && filteredModels.map((data, index) => (
                                    <Box key={index} marginTop='8px'>
                                        <Box className='mt-2 mt-md-0' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderRadius: '12px', background: '#fafafa', padding: '12px 8px 8px 16px', maxHeight: '68px' }}>
                                            <Box display='flex'>
                                                <Ptext size='14' lineHeight='20' fontWeight='600' marginTop='0'>
                                                    {data?.title}
                                                </Ptext>
                                                <Ptext size='14' lineHeight='20' fontWeight='700' marginTop='0'>
                                                    &nbsp; {switchTab == '0' ? `/ $${data.rent}mo` : null}
                                                    {switchTab == '1' ? `/ $${data.price_incl_tax}` : null}
                                                    <span style={{ color: '#c5c5c5' }}>&nbsp;x1</span>
                                                </Ptext>

                                            </Box>
                                            <Box display='flex' alignItems='center' height='48px' width='48px' backgroundColor='#ffffff' borderRadius="12px">
                                                <img src={data?.images?.[0]?.original} />
                                            </Box>
                                        </Box>
                                    </Box>
                                ))}
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '24px' }}>
                            <Col xs={6}>
                                <BasicButton
                                    MUIicon={<ArrowBackIosIcon style={{ height: '26px', width: '18px', marginLeft: '8px' }} />}
                                    padding={'10px 20px'}
                                    width={'100%'}
                                    text={'Back'}
                                    background={'transparent'}
                                    color={'#1d1d1d'}
                                    border={'1px solid #1d1d1d'}
                                    onClick={() => setCurrentStep(0)}
                                />
                            </Col>
                            <Col xs={6} >
                                <BasicButton
                                    text={`Confirm`}
                                    background={'#1d1d1d'}
                                    color={'#ffffff'}
                                    border={'1px solid #1d1d1d'}
                                    // onClick={() => setIsModalActive(true)}
                                    iconWidth={'24px'}
                                    iconMarginRight={'8px'}
                                    onClick={() => setCurrentStep(0)}
                                // onClick={() => handleAddCart(productDetails?.url)}
                                />
                            </Col>
                        </Row>
                    </>
                    :
                    <>
                        <Box display='flex' flexWrap={'wrap'} justifyContent='space-between'>
                            <H1text size='30' lineHeight='48' fontWeight='600' marginTop='0'>Your order</H1text>
                            <TabSwitch width={'124px'} tabs={tabs} tranferData={tranferData} />
                        </Box>
                        <Box marginTop='24px'>
                            <CustomStepper steps={steps} activeStep={currentStep} />
                        </Box>
                        <Box marginTop='32px'>
                            <Row>
                                {currentStep === 0 && (
                                    <Col xs={12} md={8}>
                                        <Box borderRadius='12px' padding='20px 24px' backgroundColor='#fafafa'>
                                            <Ptext size='16' lineHeight='24' fontWeight='600' marginTop='0'>
                                                Contact info
                                            </Ptext>
                                            <BasicInput
                                                label="Your email"
                                                name="email"
                                                type="email"
                                                value={email}
                                                onChange={(e) => setEmailValue(e.target.value)}
                                                style={{ marginTop: '20px' }}
                                                error={email != "" ? !validateEmail(email) : ""}
                                            />

                                            {/* <BasicInput
                                                label="Your phone"
                                                name="phone"
                                                type="tel"
                                                value={phone}
                                                onChange={(e) => setPhoneValue(e.target.value)}
                                                style={{ marginTop: '20px' }}
                                                maxLength={10}
                                                error={phone != "" ? !validatePhoneNumber(phone) : ""}
                                            /> */}
                                            <PhoneInput
                                                style={{ marginTop: '20px' }}
                                                // className="form-control phone-input "
                                                defaultCountry="US"
                                                placeholder="Phone No"
                                                value={phone}
                                                onChange={handlePhoneNumber}
                                                className="phone-input"
                                                maxLength={"15"}
                                            />
                                        </Box>
                                    </Col>
                                )}
                                {currentStep === 1 && (
                                    <>
                                        <Col xs={12} md={8}>
                                            <Box borderRadius='12px' padding='20px 24px' backgroundColor='#fafafa'>
                                                <Ptext size='16' lineHeight='24' fontWeight='600' marginTop='0'>
                                                    Delivery Info
                                                </Ptext>
                                                {/* Add delivery related inputs */}
                                                <Row>
                                                    <Col xs={12} md={12}>
                                                        <PlaceholderInput
                                                            label="Title"
                                                            placeholder="Title"
                                                            name="title"
                                                            data={titleList}
                                                            value={title}
                                                            onChange={(e) => handleTitle(e)}
                                                            style={{ marginTop: '20px' }}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <BasicInput
                                                            label="First name"
                                                            name="firstName"
                                                            type="text"
                                                            value={firstName}
                                                            onChange={(e) => setFirstName(e.target.value)}
                                                            style={{ marginTop: '20px' }}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <BasicInput
                                                            label="Last name"
                                                            name="lastName"
                                                            type="text"
                                                            value={lastName}
                                                            onChange={(e) => setLastName(e.target.value)}
                                                            style={{ marginTop: '20px' }}
                                                        />
                                                    </Col>

                                                    <Col xs={12} md={6}>
                                                        <PlaceholderInput
                                                            label="Country"
                                                            placeholder="Country"
                                                            name="country"
                                                            data={countryData}
                                                            value={country}
                                                            onChange={(e) => handleCountry(e)}
                                                            style={{ marginTop: '20px' }}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <BasicInput
                                                            label="State"
                                                            name="State"
                                                            type="text"
                                                            value={state}
                                                            onChange={(e) => setState(e.target.value)}
                                                            style={{ marginTop: '20px' }}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        {/* <PlaceholderInput
                                                            label="City"
                                                            placeholder="City"
                                                            name="city"
                                                            value={city}
                                                            onChange={(e) => setCity(e.target.value)}
                                                            style={{ marginTop: '20px' }}
                                                        /> */}
                                                        <BasicInput
                                                            label="City"
                                                            name="City"
                                                            type="city"
                                                            value={city}
                                                            onChange={(e) => setCity(e.target.value)}
                                                            style={{ marginTop: '20px' }}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <BasicInput
                                                            label="Postal Code"
                                                            name="Postal Code"
                                                            type="postalCode"
                                                            value={postalCode}
                                                            onChange={(e) => setPostalCode(e.target.value)}
                                                            style={{ marginTop: '20px' }}
                                                        />
                                                    </Col>


                                                    <Col xs={12} md={12}>
                                                        <BasicInput
                                                            label="Address"
                                                            name="address"
                                                            type="text"
                                                            value={address}
                                                            onChange={(e) => setAddress(e.target.value)}
                                                            style={{ marginTop: '20px' }}
                                                        />
                                                    </Col>
                                                    <Row style={{ marginTop: '24px' }}>
                                                        <Col>
                                                            <Ptext size='13' lineHeight='18' fontWeight='500' marginTop='0' color='#757575'>
                                                                In the US, our standard delivery is FedEx 3-5 working days, shipping out of Los Angeles, California. West coast customers typically wait 1 or 2 days, while delivery to the East coast typically takes the full 4 or 5 days.
                                                            </Ptext>
                                                            <Ptext size='13' lineHeight='18' fontWeight='500' marginTop='16' color='#757575'>
                                                                In the UK, we offer overnight delivery with FedEx. Once we dispatch your order, we will send you tracking details
                                                            </Ptext>

                                                        </Col>
                                                    </Row>
                                                </Row>


                                            </Box>
                                        </Col>
                                    </>
                                )}

                                {currentStep === 2 && (
                                    <>
                                        <Col xs={12} md={8}>
                                            <Box borderRadius='12px' padding='20px 24px' backgroundColor='#fafafa'>
                                                <Ptext size='16' lineHeight='24' fontWeight='600' marginTop='0'>
                                                    Payment
                                                </Ptext>
                                                {/* Add payment related inputs */}
                                                {/* <form onSubmit={handleSubmit}> */}
                                                <Row>
                                                    <Col xs={12} md={12} style={{ marginTop: '20px' }}>
                                                        {/* <BasicInput
                                                            label="Card Number"
                                                            type="cardNumber"
                                                            name="cardNumber"
                                                            value={cardNumber}
                                                            style={{ marginTop: '20px' }}
                                                            onChange={handleCardNumberChange}
                                                            // setValue={setValue}
                                                            error={cardNumber != "" ? !isValidCardNumber(cardNumber) : ""}
                                                        /> */}
                                                        <CardNumberElement className="form-control"
                                                            options={{
                                                                style: {
                                                                    base: {
                                                                        fontSize: "14px",
                                                                        fontFamily: "Manrope, sans-serif",
                                                                        color: "#808080",
                                                                        fontWeight: "400",
                                                                        padding: '10px',

                                                                    },
                                                                    invalid: {
                                                                        color: "red",
                                                                    },
                                                                },
                                                                placeholder: "Card Number",
                                                            }}
                                                            onChange={handleCardNumberChange}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6} style={{ marginTop: '20px' }}>
                                                        {/* <BasicInput
                                                            label="MM / YY"
                                                            type="expiry"
                                                            name="expiry"
                                                            value={expiry}
                                                            style={{ marginTop: '20px' }}
                                                            onChange={handleExpiryChange}
                                                            error={expiry != "" ? !isValidExpiryDate(expiry) : ""}
                                                        /> */}
                                                        <CardExpiryElement className="form-control"
                                                            options={{
                                                                style: {
                                                                    base: {
                                                                        fontSize: "14px",
                                                                        fontFamily: "Manrope, sans-serif",
                                                                        color: "#808080",
                                                                        fontWeight: "400",
                                                                        padding: '10px'
                                                                    },
                                                                    invalid: {
                                                                        color: "red",
                                                                    },
                                                                },
                                                                placeholder: "MM/YY",
                                                            }}
                                                            onChange={handleExpiryChange}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6} style={{ marginTop: '20px' }}>
                                                        {/* <BasicInput
                                                            label="CVV"
                                                            type="cvv"
                                                            name="cvv"
                                                            value={cvv}
                                                            style={{ marginTop: '20px' }}
                                                            onChange={handleCvvChange}
                                                            error={cvv != "" ? !isValidCVV(cvv) : ""}
                                                        /> */}
                                                        <CardCvcElement className="form-control"
                                                            options={{
                                                                style: {
                                                                    base: {
                                                                        fontSize: "14px",
                                                                        fontFamily: "Manrope, sans-serif",
                                                                        color: "#808080",
                                                                        fontWeight: "400",
                                                                        padding: '10px'
                                                                    },
                                                                    invalid: {
                                                                        color: "red",
                                                                    },
                                                                },
                                                                placeholder: "CVV"
                                                            }}
                                                            onChange={handleCvvChange}

                                                        />
                                                    </Col>
                                                    <Col xs={12} md={12} style={{ marginTop: '24px' }}>
                                                        {/* <CustomCheckbox
                                                            checked={rememberMe}
                                                            onChange={handleCheckboxChange}
                                                            label={
                                                                <Ptext size="14" lineHeight="20" fontWeight="500" marginTop="0">
                                                                    Remember me
                                                                </Ptext>
                                                            }
                                                        /> */}
                                                    </Col>
                                                    {/* <Col xs={12} md={12} style={{ marginTop: '24px' }}>
                                                        <button type="submit">Submit</button>
                                                    </Col> */}
                                                </Row>
                                                {/* </form> */}
                                            </Box>
                                        </Col>
                                    </>
                                )}
                                <Col xs={12} md={4} >
                                    {/* Additional content for step 1 */}

                                    {/* need recall single selected data */}
                                    {getToken() && !userMe?.is_anonymous ?
                                        <>
                                            {basket?.length > 0 && basket.map((data, index) => (
                                                <Box key={index} className={`mt-2 ${index !== 0 ? 'mt-md-2' : 'mt-md-0'}`} style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderRadius: '12px', background: '#fafafa', padding: '12px 8px 8px 16px', maxHeight: '68px' }}>
                                                    <Box>
                                                        <Ptext size="14" lineHeight="20" fontWeight="600" marginTop="0">
                                                            {truncateTextByChars(data?.product_name, 15)} {/* Truncate after 30 characters */}
                                                        </Ptext>
                                                        <Ptext size='14' lineHeight='20' fontWeight='700' marginTop='0'>
                                                            {switchTab == '0' ? `$${data?.price_incl_tax}mo` : null}
                                                            {switchTab == '1' ? `$${data?.price_incl_tax}` : null}
                                                            <span style={{ color: '#c5c5c5' }}>&nbsp;x{data?.quantity}</span>
                                                        </Ptext>
                                                    </Box>
                                                    {basket.length > 1 &&
                                                        <Box
                                                            style={{
                                                                position: 'absolute', top: '-6px',
                                                                right: '-6px', background: '#EDEDED', padding: '10px',
                                                                width: '18px',
                                                                height: '18px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                borderRadius: '50%'
                                                            }}>

                                                            <CloseIcon
                                                                style={{
                                                                    color: 'grey', width: '18px', height: '18px', cursor: 'pointer',
                                                                }}
                                                                onClick={() => handleClearCart(data, data?.quantity, data?.url)} />
                                                        </Box>}
                                                    <Box display='flex' alignItems='center' height='48px' width='48px' backgroundColor='#ffffff' borderRadius="12px">
                                                        <img src={data?.product_images?.[0]?.original} />
                                                    </Box>
                                                </Box>
                                            ))}
                                        </>
                                        :
                                        <>
                                            {filteredModels?.length > 0 && filteredModels.map((data, index) => (
                                                <Box key={index} className={`mt-2 ${index !== 0 ? 'mt-md-2' : 'mt-md-0'}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderRadius: '12px', background: '#fafafa', padding: '12px 8px 8px 16px', maxHeight: '68px' }}>
                                                    <Box>
                                                        <Ptext size="14" lineHeight="20" fontWeight="600" marginTop="0">
                                                            {truncateTextByChars(data?.title, 15)} {/* Truncate after 30 characters */}
                                                        </Ptext>
                                                        <Ptext size='14' lineHeight='20' fontWeight='700' marginTop='0'>
                                                            {switchTab == '0' ? `$${data?.rent}mo` : null}
                                                            {switchTab == '1' ? `$${data?.price_incl_tax}` : null}
                                                            <span style={{ color: '#c5c5c5' }}>&nbsp;x1</span>
                                                        </Ptext>
                                                    </Box>
                                                    <Box display='flex' alignItems='center' height='48px' width='48px' backgroundColor='#ffffff' borderRadius="12px">
                                                        <img src={data?.images?.[0]?.original} />
                                                    </Box>
                                                </Box>
                                            ))}
                                        </>
                                    }

                                    {/* need recall */}
                                    {/* cart data  */}

                                    {/* cart data  */}


                                    {/* <Box>
                                        <BasicButton
                                            MUIicon={<Box marginRight={'8px'}><AddIcon /></Box>}
                                            text={`Add more`}
                                            background={'#f6f6f6'}
                                            color={'#4579F5'}
                                            marginTop={'16px'}
                                            border={'none'}
                                            padding={'13.16px 20px'}
                                            onClick={() => addMore()}
                                        />
                                    </Box> */}
                                    <Box>
                                        <BasicInput label={'Promocode'} style={{ marginTop: '20px', background: '#f6f6f6', borderRadius: '12px' }} />
                                    </Box>
                                </Col>
                            </Row>



                        </Box>
                        <Row>
                            <Col xs={12} md={8} >
                                <Box className='flex-wrap flex-md-nowrap' display='flex' justifyContent='space-between' gap={3} marginTop='24px'>
                                    {/* {currentStep > 0 && ( */}
                                    <BasicButton
                                        MUIicon={<ArrowBackIosIcon style={{ height: '26px', width: '18px', marginLeft: '8px' }} />}
                                        padding={'10px 20px'}
                                        width={'48px'}
                                        text={''}
                                        background={'transparent'}
                                        color={'#1d1d1d'}
                                        border={'1px solid #1d1d1d'}
                                        onClick={handleBack}
                                        height={'48px'}
                                    />
                                    <BasicButton
                                        icon={appleBrand}
                                        text={`Apple pay`}
                                        background={'transparent'}
                                        color={'#1d1d1d'}
                                        border={'1px solid #1d1d1d'}
                                        // onClick={() => setIsModalActive(true)}
                                        iconWidth={'24px'}
                                        iconMarginRight={'8px'}
                                        height={'48px'}
                                    // onClick={() => handleAddCart(productDetails?.url)}
                                    />

                                    {/* )} */}
                                    <Box display='flex' justifyContent='flex-end' width='100%'>
                                        {currentStep < steps.length - 1 ? (
                                            <BasicButton
                                                text={anonymousDataloader ? <CircularProgress style={{ 'color': 'white', 'width': '25px', 'height': '25px', marginLeft: '10px' }} /> : 'Next'}
                                                fontWeight={'500'}
                                                padding={'11px 20px'}
                                                background={'#1d1d1d'}
                                                color={'#ffffff'}
                                                border={'1px solid #1d1d1d'}
                                                height={'48px'}
                                                onClick={() => !anonymousDataloader && handleNext(currentStep)}
                                            />
                                        ) : (
                                            <BasicButton
                                                text={<>
                                                    &nbsp; {switchTab == '0' ? `Buy / $${productDetails?.rent}mo` : null}
                                                    {switchTab == '1' ? `Buy / $${productDetails?.price_incl_tax}` : null}
                                                    <span style={{ color: '#c5c5c5' }}></span>
                                                    {submitLoader ? <CircularProgress style={{ 'color': 'white', 'width': '25px', 'height': '25px', marginLeft: '10px' }} /> : ''}
                                                </>}
                                                style={{ alignItems: 'center', display: 'flex' }}
                                                fontWeight={'500'}
                                                padding={'11px auto'}
                                                background={'#1d1d1d'}
                                                color={'#ffffff'}
                                                border={'1px solid #1d1d1d'}
                                                onClick={() => {
                                                    // Handle form submission
                                                    !submitLoader && handleSubmit();

                                                }}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            </Col>
                        </Row>
                    </>}
        </WrapperDiv >
    )
}

export default InnerContent
