import { LOGOUT, LOGOUT_ERROR, LOGOUT_SUCCESS } from "./constant";

const logOutReducer = (state = {}, action) => {

    switch (action.type) {
        case LOGOUT:
            return {
                ...state,
                loading: true,
                error: '',
            }
        case LOGOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: action.payload,
                type: LOGOUT_SUCCESS,
                error: '',
            }
        case LOGOUT_ERROR:
            return {
                ...state,
                loading: false,
                type: LOGOUT_ERROR,
                error: action.payload,
            }
        default:
            return{
                state
            }    
    }
}
export default logOutReducer;