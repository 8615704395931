import { COUNTRIES, COUNTRIES_ERROR, COUNTRIES_SUCCESS } from "./constant";

const countriesReducer = (state = {}, action) => {

    switch (action.type) {
        case COUNTRIES:
            return { ...state, loading: true, error: '' };
        case COUNTRIES_ERROR:
            return { ...state, type: COUNTRIES_ERROR, loading: false, error: action.payload };
        case COUNTRIES_SUCCESS:
            return { ...state, type: COUNTRIES_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
}
export default countriesReducer