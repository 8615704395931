import { ORDER_HISTORY, ORDER_HISTORY_ERROR, ORDER_HISTORY_SUCCESS } from "./constant"


const orderHistoryReducer = (state={},action)=>{

    switch(action.type){
        case ORDER_HISTORY:{
            return {
                ...state,
                loading:true,
                error:''
            };
        }
        case ORDER_HISTORY_ERROR:{
            return {
                ...state,
                loading:false,
                type:ORDER_HISTORY_ERROR,
                error:action.payload
            };
        }
        case ORDER_HISTORY_SUCCESS:{
            return {
                ...state,
                type:ORDER_HISTORY_SUCCESS,
                loading:false,
                payload:action.payload,
                error:''
            };
        }
        default :
        return state;
    }
}
export default orderHistoryReducer