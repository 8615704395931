import { PRODUCT_DETAILS, PRODUCT_DETAILS_ERROR, PRODUCT_DETAILS_SUCCESS } from "./constant"


const productDetailsReducer = (state={},action)=>{

    switch(action.type){
        case PRODUCT_DETAILS:{
            return {
                ...state,
                loading:true,
                error:''
            };
        }
        case PRODUCT_DETAILS_ERROR:{
            return {
                ...state,
                loading:false,
                type:PRODUCT_DETAILS_ERROR,
                error:action.payload
            };
        }
        case PRODUCT_DETAILS_SUCCESS:{
            return {
                ...state,
                type:PRODUCT_DETAILS_SUCCESS,
                loading:false,
                payload:action.payload,
                error:''
            };
        }
        default :
        return state;
    }
}
export default productDetailsReducer