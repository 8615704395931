import { React, useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import "./Questions.scss"
import { Col, Container, Row } from 'react-bootstrap';
import url from 'services/urls.json';
import { getMethod, getMethodWithToken } from 'services/apiServices';
import { ClearTokenRedirectLogin } from 'context/context';
import { useNavigate } from 'react-router-dom';
import Ptext from 'components/Ptext/Ptext';
import { Box } from '@mui/material';
import H1text from 'components/H1text/H1text';

export default function Questions(props) {

    const navigate=useNavigate()
    const [questions , setQuestions]=useState([])
    const [id, setId] = useState(1);
    useEffect(()=>{
        handleQuestion()
    },[])

    const handleQuestion = async()=>{
        let apiUrl = url.question
        let response = localStorage.getItem('token') ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl)
        if(response?.status === 200 || response?.status === 201){
            setQuestions(response.data)
        }
        else if (response?.status === 401 || response?.status === 403) {
            ClearTokenRedirectLogin(navigate)
        }
    }
    const handleChange = (id) => {
        setId(id)
    };
    return (
        <Box
        sx={{
            padding: {
                xs: '16px 2.5px',  
                sm: '32px 5px',  
                md: '48px 10px',  
                lg: '96px 20px',  
            },
        }}
        className='questions text-left pe-3 ps-3 pe-md-0 ps-md-0' style={{ fontFamily: "Manrope, sans-serif", background:props.bgColor?props.bgColor:'#fafafa' }}>
            <Container>
                <Row>
                    <Col md={4} className=" p-0" >
                        <H1text size={'36'} lineHeight='36' fontWeight='600'  className='mb-3'>Frequently  Asked  Questions</H1text>
                    </Col>
                    <Col md={8} style={{borderTop:'1px solid #e5e7eb', borderBottom:'1px solid #e5e7eb', padding:'0'}}> {questions && questions.map((data, index) => (
                        <Accordion key={index} expanded={id === data?.id ? true : false} onChange={() => handleChange(data?.id)} className={`accordion-item ${id === data.id ? 'expanded' : ''} ${data.id === 1 ? 'no-border' : ''}`}
                        >
                            <AccordionSummary
                                expandIcon={
                                    id === data?.id ? <RemoveOutlinedIcon /> : <AddOutlinedIcon />
                                }
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                               <Ptext size={'20'} lineHeight={'28'} fontWeight={600} marginTop='0' >{data?.questionText}</Ptext>
                            </AccordionSummary>
                            <AccordionDetails>
                                {data?.answer && data?.answer.length>0 && data?.answer.map((ans , index)=>(
                                    <Ptext key={index} size={'14'} lineHeight={'24'} fontWeight={500} marginTop='0'>
                                     {ans?.answerText}
                                 </Ptext>
                                ))}
                               
                            </AccordionDetails>
                        </Accordion>
                    ))}</Col>
                </Row>
            </Container>

        </Box>
    );
}
