import { SIGN_IN, SIGN_IN_ERROR, SIGN_IN_SUCCESS } from "./constant";

const signInReducer = (state = {}, action) => {
    switch (action.type) {
        case SIGN_IN:
            return {
                ...state, loading: true, error: ''
            }
        case SIGN_IN_ERROR:
            return {
                ...state, loading: false, error: action.payload, type: SIGN_IN_ERROR
            }
        case SIGN_IN_SUCCESS:
            return {
                ...state, loading: false, error: '', payload: action.payload, type: SIGN_IN_SUCCESS
            }
        default:
            return{
                state
            }
    }
}
export default signInReducer;