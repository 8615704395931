// PaymentForm.js
import React, { useState } from 'react';
import {
  Elements, useStripe, useElements, CardNumberElement,
  CardCvcElement, CardExpiryElement, CardElement
} from "@stripe/react-stripe-js";
import { toast } from 'react-toastify';
import { Container, Col, Row, Button, FloatingLabel, Form } from "react-bootstrap";
import AppleIcon from '@mui/icons-material/Apple';
import { useDispatch, useSelector } from "react-redux";

const Card = ({onPaymentMethodCreated}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);

  const customerId = useSelector(state => state?.userReducer?.payload?.data?.stripe_ID);
  const email =useSelector(state => state?.userReducer?.payload?.data?.email);

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!stripe || !elements) {
      return;
    }
  
    setIsProcessing(true);
  
  
    const { error, token } = await stripe.createToken(elements.getElement(CardNumberElement));
  
    setIsProcessing(false);
  
    if (error) {
      toast.error(error.message);
    } else if (token) {
      await onPaymentMethodCreated(token?.id,customerId,token?.card?.id);
    }
  };
  
  const [screenSize, getDimension] = useState({
    width: window.innerWidth,
    height: window.innerHeight
});
  let cardFontSize = '';
    if (screenSize.width <= 1100) {
        cardFontSize = "12px";
    } else if (screenSize.width <= 1500) {
        cardFontSize = "14px";
    } else {
        cardFontSize = "14px";
    }

  return (
    <Container fluid>
    <form onSubmit={handleSubmit}>
        <Row>
            <Col md={12} className="mt-3">
                
                <CardNumberElement className="form-control"
                    options={{
                        style: {
                            base: {
                                fontSize: cardFontSize,
                                fontFamily: "Manrope, sans-serif",
                                color: "#808080",
                                fontWeight: "400",
                                padding:'10px'
                            },
                            invalid: {
                                color: "red",
                            },
                        },
                        placeholder: "Card Number",
                    }}
                    
                />
            </Col>
            <Col md={6} className="mt-3">
              
                <CardExpiryElement className="form-control"
                    options={{
                        style: {
                            base: {
                                fontSize: cardFontSize,
                                fontFamily: "Manrope, sans-serif",
                                color: "#808080",
                                fontWeight: "400",
                                 padding:'10px'
                            },
                            invalid: {
                                color: "red",
                            },
                        },
                        placeholder: "MM/YY",
                    }}
                
                />
            </Col>
            <Col md={6} className="mt-3">
                

                <CardCvcElement className="form-control"
                    options={{
                        style: {
                            base: {
                                fontSize: cardFontSize,
                                fontFamily: "Manrope, sans-serif",
                                color: "#808080",
                                fontWeight: "400",
                                 padding:'10px'
                            },
                            invalid: {
                                color: "red",
                            },
                        },
                        placeholder:"CVV"
                    }}
                   
                />

            </Col>
            <Col md={12} className="mt-3">
                <Button variant="dark" className=" w-100 fw-bold rentButton pe-4 ps-4 pt-2 pb-2" type='submit'
                    disabled={!stripe || isProcessing}>{isProcessing ? 'Processing...' : 'Order Right Now'}</Button>
                <Button variant="outline-dark" className=" w-100 fw-bold CartButton pe-4 ps-4 pt-2 pb-2 mt-3" >
                    <AppleIcon />Apple pay
                </Button>
            </Col>
        </Row>
    </form>

</Container>
  );
};

export default Card;
