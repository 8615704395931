import { ORDER_DETAILS, ORDER_DETAILS_ERROR, ORDER_DETAILS_SUCCESS } from "./constant"


const orderDetailsReducer = (state={},action)=>{

    switch(action.type){
        case ORDER_DETAILS:{
            return {
                ...state,
                loading:true,
                error:''
            };
        }
        case ORDER_DETAILS_ERROR:{
            return {
                ...state,
                loading:false,
                type:ORDER_DETAILS_ERROR,
                error:action.payload
            };
        }
        case ORDER_DETAILS_SUCCESS:{
            return {
                ...state,
                type:ORDER_DETAILS_SUCCESS,
                loading:false,
                payload:action.payload,
                error:''
            };
        }
        default :
        return state;
    }
}
export default orderDetailsReducer