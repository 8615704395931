import { USER,USER_ERROR,USER_SUCCESS } from "./constant";
import { getMethodWithToken } from "services/apiServices";
import url from 'services/urls.json';
import { takeLatest,put,take } from "redux-saga/effects";
import { ClearTokenRedirectLogin } from "context/context";

function* getUserApi(action){
    try{
        let response = yield getMethodWithToken(url.user);
        if(response?.status === 200 || response?.status === 201){
            yield put({type:USER_SUCCESS,payload: response})
            if(action.external && action.external.run===true){
                action.external.handleFunction(response);
            }
        }
        else {
            yield put({type:USER_ERROR,payload:response})
            if(response?.status === 401 || response?.status === 403){
                ClearTokenRedirectLogin(action.external.navigate)
              }
        }
    }
    catch (error){
        yield put({type:USER_ERROR , payload:error})
    }

}

function* userSaga(){
    yield takeLatest(USER ,getUserApi )
}

export default userSaga;