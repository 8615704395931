import { PAYMENT_INTENT } from "./constant";

const paymentIntentCallAPI =(type , data , external={})=>{

    switch(type){
        case PAYMENT_INTENT:
            return {
                type:type,
                data:data,
                external:external
            }
        default:
            return{
                type:type
            }    
    }
}
export default paymentIntentCallAPI;