import { CATEGORY_DROPDOWN, CATEGORY_DROPDOWN_ERROR, CATEGORY_DROPDOWN_SUCCESS } from "./constant"


const categoryDropdownReducer = (state={},action)=>{

    switch(action.type){
        case CATEGORY_DROPDOWN:{
            return {
                ...state,
                loading:true,
                error:''
            };
        }
        case CATEGORY_DROPDOWN_ERROR:{
            return {
                ...state,
                loading:false,
                type:CATEGORY_DROPDOWN_ERROR,
                error:action.payload
            };
        }
        case CATEGORY_DROPDOWN_SUCCESS:{
            return {
                ...state,
                type:CATEGORY_DROPDOWN_SUCCESS,
                loading:false,
                payload:action.payload,
                error:''
            };
        }
        default :
        return state;
    }
}
export default categoryDropdownReducer