import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Buy from "../pages/Buy/Buy";
import Cart from "../pages/Cart/Cart";
import Store from "../pages/Store/Store";
import OurVision from "../pages/OurVision/OurVision";
import Metro from "../pages/Metro/Metro";
import Compare  from "../pages/Compare/Compare";
import SignIn from 'pages/SignIn/SignIn';
import SignUp from 'pages/SignUp/SignUp';
import ForgotPassword from 'pages/ForgotPassword/ForgotPassword';
import Home from 'pages/Home/Home';
import SetPassoword from 'pages/setPassword/setPassword';
import Checkout from 'pages/checkout/checkout';
import Delivery from 'pages/checkout/card/card';
import OrderList from 'pages/orderList/orderList';
import OrderDetail from 'pages/orderDetail/orderDetail';
import Success from 'pages/Success/Success';
import OtpScreen from 'pages/OtpScreen/OtpScreen';
import ProductDetails from 'pages/ProductDetails/ProductDetails';
import Bikes from 'pages/Bikes/Bikes';
import Favourites from 'pages/Favourites/Favourites';
import ViewAll from 'pages/ViewAll/ViewAll';
import CompanyReview from 'pages/CompanyReview/CompanyReview';
import ActiveSubscription from 'pages/ActiveSubscription/ActiveSubscription';

const AppRoutes = () => {

    const privateRoutes = [
        {
            path: "/compare",
            element: <Compare />
        },
        {
            path: "/scooters",
            element: <Buy />
        },
        {
            path: "/bikes",
            element: <Bikes />
        },
        {
            path: "/productdetails/:id",
            element: <ProductDetails />
        },
        {
            path: "/cart",
            element: <Cart />
        },
        {
            path: "/store",
            element: <Store />
        },
        {
            path: "/ourvision",
            element: <OurVision />
        },
        {
            path: "/metro",
            element: <Metro />
        },
        {
            path: "/signin",
            element: <SignIn />
        },
        {
            path: "/signup",
            element: <SignUp />
        },
        {
            path: "/success/:id",
            element: <Success />
        },
        {
            path: "/forgotpassword",
            element: <ForgotPassword />
        },
        {
            path: "/otp",
            element: <OtpScreen />
        },
        {
            path:"",
            element:<Home/>
        },
        {
            path:"/setpassword",
            element:<SetPassoword/>
        },
        {
            path:"/checkout",
            element:<Checkout/>
        },
        {
            path:"/delivery",
            element:<Delivery/>
        },
        {
            path:"/orderlist",
            element:<OrderList/>
        },
        {
            path:"/active-subscription",
            element:<ActiveSubscription/>
        },
        {
            path:"/orderdetail",
            element:<OrderDetail/>
        },
        {
            path:"/favourites",
            element:<Favourites/>
        },
        {
            path:"/rider-profile",
            element:<ViewAll/>
        },
        {
            path:"/company-review",
            element:<CompanyReview/>
        }

    ]
    return (
        <Routes>
           {privateRoutes.map((item) => (
                <Route key={item.path} path={item.path} element={item.element} />
            ))}
             <Route path="" element={<Home/>} /> 
      
      </Routes>
    )
}
export default AppRoutes