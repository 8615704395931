import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import PerfectScooter from "components/common/perfectScooter/perfectScooter";
import 'pages/OurVision/OurVision.scss';
import Ptext from "components/Ptext/Ptext";
import H1text from "components/H1text/H1text";
import womenAndBuilding from 'assets/images/womenAndBuilding.png';
import { getMethod, getMethodWithToken } from "services/apiServices";
import url from 'services/urls.json';
import OurvisionImage from 'assets/images/Ourvision.png';
import { Box } from "@mui/material";
import { motion } from "framer-motion"; 
import { containerVariants } from "services/common";

const OurVision = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const [Models, setModels] = useState([]);
    
    useEffect(() => {
        handleModels();
    }, []);

    const handleModels = async () => {
        let apiUrl = `${url.buy}?catagory=scooter&paginate=false&page_limit=0`;
        let response = localStorage.getItem("token") ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl);
        if (response?.status === 200 || response?.status === 201) {
            setModels(response.data);
        }
    };

    

    return (
        <motion.div 
            style={{ fontFamily: "Manrope, sans-serif" }} 
            className="ourVision ps-3 pe-3 ps-md-0 pe-md-0"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
        >
            <Box
                sx={{
                    padding: {
                        xs: "12px 0",
                        sm: "24px 0",
                        md: "48px 0",
                        lg: "96px 0",
                    },
                }}
                style={{ background: '#fafafa' }}
            >
                <Box className="hiddenDiv" style={{ position: 'absolute', top: '408px' }}>
                    <img src={OurvisionImage} alt="" />
                </Box>
                <Container>
                    <Row className="justify-content-between align-items-end">
                        <Col md={4} className="p-0">
                            <div className="">
                                <H1text size="54" lineHeight="54" fontWeight="600" className="heading text-black relative pt-2 mt-2 pt-sm-5 mt-sm-5" marginTop="0">
                                    Our vision
                                </H1text>
                                <Ptext size="20" lineHeight="32" fontWeight="500" marginTop="36">
                                    Emphasis needs to be put on: 1 in 10 scooters donated to kids who need it most.
                                    Until we reach the goal of 1 million. Then we will continue to see how we can help the world even more.
                                </Ptext>
                            </div>
                        </Col>
                        <Col md={7} className="p-0">
                            <div>
                                <img src={womenAndBuilding} className="w-100" style={{ borderRadius: '24px', border: '8px solid #ffffff' }} alt="Women and building" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Box>
            <Box
                sx={{
                    padding: {
                        xs: "24px 0",
                        sm: "24px 0",
                        md: "48px 0",
                        lg: "96px 0",
                    },
                }}
            >
                <Container>
                    <Row className="justify-content-between align-items-end">
                        <Col md={6}>
                            <div>
                                <img
                                    src="https://metrorides.theclientdemos.com/assets/images/a2.png"
                                    alt="img"
                                    className="w-100"
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="marginDiv">
                                <H1text
                                    size="36"
                                    lineHeight="36"
                                    fontWeight="600"
                                    className="heading text-black relative pt-2 mt-2 pt-sm-5 mt-sm-5"
                                    marginTop="0"
                                >
                                    We keep things ECO-Friendly, as well as contributing to life-changing causes
                                </H1text>
                                <Ptext size="14" lineHeight="24" fontWeight="500" marginTop="36">
                                    We work directly with School Boards, Metro Communities, and Students personally. Ensuring those in
                                    need receive an effective form of transportation, new or fresh refurbished rides. A win for the
                                    carbon footprint and a great use of resources. Our goal is to remain carbon-positive as long as we
                                    can serve you.
                                </Ptext>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Box>
            <div>
                <PerfectScooter
                    heading="Your own perfect scooter"
                    for="Scooter"
                    image="https://metrorides.theclientdemos.com/assets/images/cta.png"
                    top="-96px"
                    models={Models}
                    bgColor="#ffffff"
                />
            </div>
        </motion.div>
    );
};

export default OurVision;
