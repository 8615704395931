import { PAYMENT_INTENT, PAYMENT_INTENT_ERROR, PAYMENT_INTENT_SUCCESS } from "./constant";

const paymentIntentReducer = (state = {}, action) => {

    switch (action.type) {
        case PAYMENT_INTENT:
            return { ...state, loading: true, error: '' }
        case PAYMENT_INTENT_ERROR:
            return { ...state, loading: false, error: action.payload, type: PAYMENT_INTENT_ERROR }
        case PAYMENT_INTENT_SUCCESS:
            return { ...state, loading: false, payload: action.payload, type: PAYMENT_INTENT_SUCCESS, error: '' }
        default:
            return {state}    
    }
}
export default paymentIntentReducer