import React, { useState } from 'react'
import { Button, Carousel, Col, Container, Row } from 'react-bootstrap'
import styled from "styled-components";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import "pages/ProductDetails/ProductDetails.scss";
import { Box } from '@mui/system';
import Ptext from 'components/Ptext/Ptext';

import Cart from 'assets/svg/cart.svg'
import Like from 'assets/svg/like.svg'
import Rating from 'components/Rating/Rating';
import BasicButton from 'components/Buttons/BasicButton/BasicButton';
import ImageTextCard from 'components/Cards/ImageTextCard/ImageTextCard';
import H1text from 'components/H1text/H1text';

import cardImage1 from 'assets/images/casualMaleScooter.png'
import cardImage2 from 'assets/images/casualWomenScooter.png'
import coupleImage from 'assets/images/coupleImage.png'

import FullWidthCard from 'components/Cards/FullWidthCard/FullWidthCard';

import womenWithCycle from 'assets/images/womenWithCycle.png'
import handWithCycle from 'assets/images/handWithCycle.png'
import wheelScooter from 'assets/images/wheelScooter.png'
import manWithMobile from 'assets/images/manWithMobile.png'
import attractive from 'assets/images/attractive.png'
import bigScooter from 'assets/images/bigScooter.png'
import CustomCarousel from 'components/CustomCarousel/CustomCarousel';

import gpay from 'assets/svg/gpay.svg'
import applepay from 'assets/svg/applepay.svg'
import { useDispatch, useSelector } from 'react-redux';
import productDetailsCallApi from 'store/productDetails/action';
import { PRODUCT_DETAILS } from 'store/productDetails/constant';
import { useEffect } from 'react';
import userCallApi from 'store/user/action';
import { USER } from 'store/user/constant';
import { CART } from 'store/cart/constant';
import cartCallApi from 'store/cart/action';
import { COMMON_DETAILS, PRODUCT_ID } from 'store/common/constant';
import { postMethod, postMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { toast } from "react-toastify"
import { commonCallApi, productID } from 'store/common/action';
import CommonModal from 'components/Modal/CommonModal';
import TabSwitch from 'components/TabSwitch/TabSwitch';
import CustomStepper from 'components/CustomStepper/CustomStepper';
import BasicInput from 'components/Inputs/BasicInput/BasicInput';
import appleBrand from 'assets/images/appleBrand.png';
import AddIcon from '@mui/icons-material/Add';
import PlaceholderInput from 'components/Inputs/PlaceholderInput/PlaceholderInput';
import CustomCheckbox from 'components/Inputs/CustomCheckbox/CustomCheckbox';
import InnerContent from './InnerContent';
import addFavouriteCallApi from 'store/addFavourite/action';
import { ADD_FAVOURITE } from 'store/addFavourite/constant';
// import FavoriteIcon from '@mui/icons-material/Favorite';
import favoriteSvg from 'assets/svg/favorite-black.svg';
import { getToken } from 'services/common';
import { CircularProgress } from "@mui/material";
import plansProductCallApi from 'store/withoutToken/plansProduct/action';
import { PLANS_PRODUCT } from 'store/withoutToken/plansProduct/constant';
import RentModal from './RentModal';

const WrapperImageDiv = styled.div`
  background: url(${props => props.image});
  height: 444px;
  max-width:346px;
  margin:auto;
  marginTop: 20px;
  cursor:pointer;
  background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
`;
const ProductDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();
    const [activeIndices, setActiveIndices] = useState({});
    const [isModalActive, setIsModalActive] = useState(false);
    const productDetails = useSelector((state) => state?.productDetailsReducer?.payload?.data);

    const [addCartloader, setAddCartloader] = useState(false);

    const handleClose = () => setIsModalActive(false);
    const detailsData = [
        {
            "id": 1,
            "icon": "",
            "name": "Top speed",
            "measure": "25 mph",
            "rating": '4'
        },
        {
            "id": 2,
            "icon": "",
            "name": "Acceleration",
            "measure": "24 mph",
            "rating": '5'
        },
        {
            "id": 3,
            "icon": "",
            "name": "Range",
            "measure": "12 mi",
            "rating": '4'
        },
        {
            "id": 4,
            "icon": "",
            "name": "Transportations",
            "measure": "12 mi",
            "rating": '3'
        },
        {
            "id": 5,
            "icon": "",
            "name": "Charge time (80%)",
            "measure": "3.5 hr",
            "rating": '4'
        },
    ]

    useEffect(() => {
        // getProductDetails();
        setFavouriteSelected(productDetails?.is_favorite)
    }, [])
    const handleSelect = (index, id) => {
        setActiveIndices((prevIndices) => ({
            ...prevIndices,
            [id]: index,
        }));
    };

    const commonId = useSelector((state) => state?.commonReducer)
    const goToCompare = () => {

        navigate('/compare');
        // params.id
        dispatch(productID(PRODUCT_ID, params));
    }

    // const [selectedProduct, setSelectedProduct] = useState({});
    const [typeLoader, setTypeLoader] = useState("")
    const handleAddCart = async (product,type) => {
       
        // setSelectedProduct(product);
        if(type!=="onlyCart"){
            setAddCartloader(true);
        }else{
            setTypeLoader(true)
        }
        let body = {
            "url": product,
            "quantity": 1
        }

        let response = localStorage.getItem("token") ? await postMethodWithToken(url.addCart, body) : await postMethod(url.addCart, body)
        if (response?.status === 200 || response?.status === 201) {
            const basket = response.data.lines.split('/api/v1/')[1]
            toast.success("Item Added to cart")
            localStorage.setItem("basketId", response?.data?.id);
            // setIsModalActive(true);
            if (getToken()) {
                dispatch(userCallApi(USER, {}, {
                    run: true,
                    handleFunction: (res) => { handleCartCount(res?.data?.basket_id,type); }
                }))
            } else {
                if(type!=="onlyCart"){
                    setIsModalActive(true);
                }else{
                    setTypeLoader(false);
                }
                setAddCartloader(false);
            }

            localStorage.setItem('cartAPI', basket)
            localStorage.setItem("clearAllCart", response.data.url)
        }
        else if (response?.status === 401) {
            navigate('/signin');
            setTypeLoader(false);
        }
        else if (response?.status === 406) {
            toast.error("Out Of Stock");
            setAddCartloader(false);
            setTypeLoader(false);
        } else {
            setAddCartloader(false);
            setTypeLoader(false);
            toast.error(response?.statusText+ " : " + response?.status);
        }
        // })

    }


    const handleCartCount = (basketId,type) => {
        dispatch(cartCallApi(CART, {}, {
            url: `ecom/baskets/${basketId}/lines`,
            run: true,
            handleFunction: (res) => handleSuccess(res,type)
        }))
    };

    const handleSuccess = (res,type) => {
        dispatch(commonCallApi(COMMON_DETAILS, { cart: res?.data?.[0]?.total_quantity }, { run: true }));
        if(type!=="onlyCart"){
            setIsModalActive(true);
        }else{
            setTypeLoader(false)
        }
        setAddCartloader(false);
    };

    const [favouriteSelected, setFavouriteSelected] = useState(false);
    // productDetails?.is_favorite
    const getProductDetails = (id) => {
        dispatch(productDetailsCallApi(PRODUCT_DETAILS, { id: id }, {
            run: true,
            handleFunction: () => { navigate(`/productdetails/${id}`, { state: { type: 'scooter' } }); window.scrollTo(0, 0); },
            toast: true,
            handleToast: (error) => { toast.error(error?.data?.detail); }
        }));
    };
    const addFavourite = (productId) => {
        setFavouriteSelected(!favouriteSelected)
        let body = {
            "productId": productId,
            "is_favourite": !favouriteSelected
        }
        dispatch(addFavouriteCallApi(ADD_FAVOURITE, body, {
            run: true,
            navigate: navigate,
            handleFunction: (res) => { !favouriteSelected ? toast.success(res?.data?.message) : toast.success(res?.data?.message); getProductDetails(params?.id) }
        }))
    };
    const plansProduct = useSelector((state) => state?.plansProductReducer?.payload?.data);
    const plansProductloader = useSelector((state) => state?.plansProductReducer?.loading);
    const [selectedRentProduct, setSelectedRentProduct] = useState({});
    const [isRentModalActive, setIsRentModalActive] = useState(false);
    const handleRentClick = (data) => {
        setSelectedRentProduct(data)
        dispatch(plansProductCallApi(PLANS_PRODUCT, { key: data?.id }, {
            run: true, navigate: navigate,
            handleError: (error) => { toast.error("Something went wrong"); },
            handleFunction: () => { setIsRentModalActive(true) }
        }))
    }
    return (
        <div className="ProductDetails ps-2 pe-2 ps-md-0 pe-md-0 " style={{ background: '#fafafa', paddingTop: '56px' }}>
            <Container className="p-0" style={{ marginBottom: "48px" }}>
                <Row className={'gx-0 gx-md-5'} style={{ background: '#ffffff', padding: '8px 8px 14px 8px', borderRadius: '24px' }}>
                    <Col sm={12} md={6} className='ps-md-0'>
                        {/* {demo && demo.length > 0 && demo.map((data, index) => ( */}
                        <div className="carouselBuy" style={{ background: '#fafafa', borderRadius: '12px' }}>
                            <Carousel
                                activeIndex={activeIndices[productDetails?.id] || 0}
                                onSelect={(index) => handleSelect(index, productDetails?.id)}
                                prevIcon={<div style={{ background: '#f6f6f6', padding: '36px 0', maxWidth: '24px', borderRadius: '12px' }} ><ArrowBackIosIcon style={{ color: 'black', marginLeft: '7px' }} /></div>}
                                nextIcon={<div style={{ background: '#f6f6f6', padding: '36px 0', maxWidth: '24px', borderRadius: '12px' }} ><ArrowForwardIosIcon style={{ color: 'black', marginRight: '7px' }} /></div>}
                                interval={null}
                            >
                                {productDetails?.images && productDetails?.images.length > 0 && productDetails?.images.map((item, index) => (
                                    <Carousel.Item key={index}>
                                        {/* <img src={item?.original} className="d-block mx-auto w-75" alt={`Slide ${index}`} style={{ height: '450px', marginTop: '20px' }} /> */}
                                        <div style={{ marginTop: '20px' }}>
                                            <WrapperImageDiv image={item?.original} >

                                            </WrapperImageDiv>
                                        </div>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                            {/* <div className="text-center fw-bold pt-4 pb-2"><h3>{productDetails?.title}</h3></div> */}
                            <div className="d-flex flex-wrap justify-content-center mt-3" style={{ paddingBottom: "16px" }} >
                                {productDetails && productDetails?.images && productDetails.images?.length > 0 && productDetails?.images.map((image, index) => (
                                    <img
                                        key={index}
                                        src={image?.original}
                                        alt={`Indicator ${index}`}
                                        className={`mt-1 indicator-image ${activeIndices[productDetails?.id] === index ? 'active' : ''}`}
                                        onClick={() => handleSelect(index, productDetails?.id)}
                                        style={{ cursor: 'pointer', width: '64px', height: '64px', objectFit: 'cover', margin: '0 5px', border: (activeIndices[productDetails?.id] === index) ? '2px solid #4579F5' : 'none', borderRadius: '15%' }}
                                    />
                                ))}
                            </div>
                        </div>
                        {/* ))} */}
                    </Col>
                    <Col sm={12} md={6} style={{ marginTop: '40px' }}>
                        <Box display={'flex'} justifyContent={'space-between'}>

                            <Box>
                                <Ptext size={"14"} lineHeight={"24"} fontWeight={"600"} marginTop={"0"} color="#ffffff"
                                    style={{ background: '#000000', padding: "2px 12px", borderRadius: '28px', maxWidth: '132px' }}
                                >For The Explorer</Ptext>
                                <Ptext size={"30"} lineHeight={"36"} fontWeight={"600"} marginTop={"16"}
                                >{productDetails?.title}</Ptext>
                            </Box>
                            <Box display="flex">
                           { localStorage.getItem("token")?
                           <>
                           <Box onClick={() => addFavourite(productDetails?.id)} display='flex' alignItems={'center'} justifyContent='center' className={'cursor-pointer'} backgroundColor="#f6f6f6" padding={"12px"} borderRadius={'12px'} height={'48px'} width={'48px'}>
                                    {!favouriteSelected ? <img src={Like} alt="" /> : <img style={{ width: '20px' }} src={favoriteSvg} alt="" />}
                                </Box>
                                <Box className={'hoverBlack ml-2 cursor-pointer d-flex justify-content-center align-items-center'} backgroundColor="#f6f6f6" padding={"12px"} borderRadius={'12px'} height={'48px'} width={'48px'} >
                                {!typeLoader? <img src={Cart} alt="" onClick={() => handleAddCart(productDetails?.url,"onlyCart")} /> :<CircularProgress style={{ width: '20px', height: '20px', cursor: 'pointer',color:"#000000" }} />}
                                </Box>
                           </>
                                :""}

                            </Box>
                        </Box>

                        <Box style={{
                            marginTop: '32px',
                            padding: '20px',
                            backgroundColor: '#fafafa',
                            borderRadius: '12px',
                        }}>
                            {productDetails?.attributes?.length > 0 && productDetails?.attributes.slice(0, 5).map((data, index) => (
                                <Row key={index} style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: index != 0 && '12px'
                                }}>
                                    <Col sm={6} style={{ display: "flex", alignItems: 'center' }}>
                                        <img className='me-2' src={data?.icon} style={{ width: '15px', height: '15px', color: '#c5c5c5' }} alt="icon" />
                                        <Ptext size={"14"} lineHeight={"24"} fontWeight={"600"} marginTop={"0"}
                                            style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}
                                        > {data?.name}</Ptext>
                                    </Col>
                                    <Col sm={6} style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <Box sx={{
                                            wordBreak: 'break-word',
                                            overflowWrap: 'break-word',
                                            minWidth: "85px",
                                            maxWidth: {
                                                sm: "120px",
                                                md: "90px",
                                                lg: "100%",
                                                xl: "100%",
                                                xxl: "100%"
                                            }
                                        }}>
                                            <Ptext size={"14"} lineHeight={"24"} fontWeight={"700"} marginTop={"0"}

                                            >{data?.value}</Ptext>
                                        </Box>
                                        <Rating rating={4} />
                                        {/* <Rating rating={data?.rating} /> */}
                                    </Col>
                                </Row>
                            ))}
                        </Box>
                        <Box>
                            <BasicButton
                                text={'Compare'}
                                background={'#f6f6f6'}
                                color={'#1d1d1d'}
                                marginTop={'32px'}
                                onClick={() => goToCompare()}
                            />
                            <BasicButton
                                text={<>
                                    {addCartloader ? <CircularProgress style={{ 'color': '#808080', 'width': '24px', 'height': '24px' }} /> :
                                        <>Buy&nbsp;/&nbsp; ${productDetails?.price_incl_tax}</>}
                                </>}
                                background={'transparent'}
                                color={'#1d1d1d'}
                                marginTop={'16px'}
                                border={'1px solid #1d1d1d'}
                                height={'48px'}
                                onClick={() => { handleAddCart(productDetails?.url); }}
                                disabled={ addCartloader || plansProductloader} 
                                // hoverBackground={"#000000"}
                            // onClick={() => handleAddCart(productDetails?.url)}
                            />
                            <CommonModal
                                padding={'12px 40px 32px 40px'}
                                width={'736px'}
                                modalActive={isModalActive}
                                handleClose={handleClose}
                                innerContent={
                                    <InnerContent setIsModalActive2={setIsModalActive} productDetails={productDetails} handleClose={handleClose} type={location?.state?.type} />
                                }
                            />

                            <BasicButton
                                text={(selectedRentProduct?.id == productDetails?.id && plansProductloader)
                                    ? <CircularProgress style={{ 'color': '#808080', 'width': '25px', 'height': '25px' }} /> : <Ptext size="16" lineHeight={'24'} fontWeight={'600'} marginTop={'0'}>Rent</Ptext>}
                                background={'#1d1d1d'}
                                color={'#ffffff'}
                                marginTop={'16px'}
                                height={'48px'}
                                border={'1px solid #000000'}
                                disabled={!productDetails?.rent || (addCartloader || plansProductloader)}
                                onClick={() => handleRentClick(productDetails)}
                            />
                            <CommonModal
                                padding={'12px 40px 32px 40px'}
                                width={'90%'}
                                modalActive={isRentModalActive}
                                handleClose={() => setIsRentModalActive(false)}
                                innerContent={
                                    <>
                                        <RentModal
                                            plansProduct={plansProduct}
                                        />
                                    </>
                                }
                            />
                        </Box>
                        <Box>
                            <Row className='gx-2'>
                                <Col sm={6} >
                                    <BasicButton
                                        icon={applepay}
                                        text={''}
                                        background={'#000000'}
                                        color={'#ffffff'}
                                        marginTop={'16px'}
                                        height={'48px'}
                                    />
                                </Col>

                                <Col sm={6} >
                                    <BasicButton
                                        icon={gpay}
                                        text={''}
                                        background={'#000000'}
                                        color={'#ffffff'}
                                        marginTop={'16px'}
                                        height={'48px'}
                                    />
                                </Col>
                            </Row>

                        </Box>
                    </Col>

                </Row>
            </Container>
            <Box style={{ paddingTop: "96px", paddingBottom: "96px", background: "#ffffff" }}>
                <Container className="p-0" style={{}}>
                    <H1text size={'36'} lineHeight={'36'} fontWeight={'600'}>Inspired For The Commute</H1text>
                    <Row className=' gy-lg-0 gy-3' style={{ marginTop: "48px" }}>
                        <Col sm={6}>
                            <ImageTextCard
                                image={cardImage1}
                                height={'400px'}
                                heading={'Transportation Compatible'}
                                subHeading={'Metro Scooters are purposefully designed for its name, to accompany you on public Metro'}
                            />
                        </Col>
                        <Col sm={6}>
                            <ImageTextCard
                                image={cardImage2}
                                height={'400px'}
                                heading={'Boardroom Ready'}
                                subHeading={'Inspired by students and workers alike, your Metro can be conveniently and discretely stowed'}

                            />
                        </Col>
                    </Row>

                </Container>
            </Box>

            {/* <Container className="p-0" > */}
            <Box style={{ paddingBottom: "8px" }}>
                {/* <FullWidthCard image={coupleImage}/> */}
                <ImageTextCard
                    image={coupleImage}
                    height={'504px'}
                    borderRadius={'0'}
                    color={"#000000"}
                    headingSize={'36px'}
                    subHeadingSize={'14px'}
                    heading={'Power'}
                    subHeading={'Our e-scooters run off premium batteries that charge fast and last a long time. You can count on reliable performance, day in and day out'}
                    bottom={'96px'}
                    subHeadingMarginTop={'36px'}
                    subHeadingMaxWidth={"259px"}
                />
            </Box>
            <Box style={{ paddingTop: "8px" }}>
                {/* <FullWidthCard image={coupleImage}/> */}
                <ImageTextCard
                    image={bigScooter}
                    height={'504px'}
                    borderRadius={'0'}
                    color={"#000000"}
                    headingSize={'36px'}
                    subHeadingSize={'14px'}
                    heading={'Range'}
                    subHeading={'Our latest models offer impressive range that lets you go further, without fail. Compared to our competitors models, ours charge faster, giving you the freedom to explore more and worry less about battery life. Our commitment to innovation, performance, and quality shines through, making every ride a more convenient and enjoyable experience'}
                    bottom={'96px'}
                    right={'165px'}
                    subHeadingMarginTop={'36px'}
                    subHeadingMaxWidth={"453px"}
                />
            </Box>
            {/* </Container> */}


            <Container className="p-0" style={{ marginBottom: "48px" }}>
                <Box style={{ paddingTop: "48px", paddingBottom: "96px" }}>
                    <H1text size={'36'} lineHeight={'36'} fontWeight={'600'}>Gorgeous design</H1text>
                    <Row className=' gy-lg-0 gy-3' style={{ marginTop: "48px" }}>
                        <Col sm={6}>
                            <ImageTextCard
                                image={womenWithCycle}
                                height={'504px'}
                            />
                        </Col>
                        <Col sm={6}>
                            <Row className='g-3'>
                                <Col sm={12}>
                                    <ImageTextCard
                                        image={handWithCycle}
                                        height={'244px'}
                                    />
                                </Col>
                                <Col sm={6}>
                                    <ImageTextCard
                                        image={wheelScooter}
                                        height={'244px'}
                                    />
                                </Col>
                                <Col sm={6}>
                                    <ImageTextCard
                                        image={manWithMobile}
                                        height={'244px'}
                                    />
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Box>
                <Box>
                    <Row className={''} style={{ padding: "28px 0" }}>
                        <Col md={6} >
                            <ImageTextCard
                                image={attractive}
                                height={'360px'}
                            />
                        </Col>
                        <Col md={6} style={{
                            justifyContent: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            <Box width={"100%"}>

                            </Box>
                            <Ptext size={'14'} lineHeight={'24'} fontWeight={'500'} style={{ maxWidth: "418px" }}>
                                <H1text size={'36'} lineHeight={'36'} fontWeight={'600'} marginBottom={'29'}>Delivery</H1text>
                                In the United States, we proudly offer standard delivery with a 3-5 day window, shipping from our hub in New York City. For those on the East Coast, you can typically expect your e-scooter in just 1-2 days, while customers across the rest of the country will generally receive their orders within the full 4-5 day timeframe.
                                For our overseas customers, we provide expedited overnight delivery. As soon as your order is dispatched, we'll send you the tracking details for your convenience
                            </Ptext>
                        </Col>
                    </Row>

                </Box>
            </Container>
            <Box style={{ background: "#ffffff", paddingTop: '96px', paddingBottom: '96px' }}>
                <Container className="p-0" style={{ backgroundColor: '#ffffff', marginBottom: "48px" }}>

                    <Col md={12} style={{ marginBottom: '48px' }} >
                        <Row className=" d-flex justify-content-between align-items-center">
                            <Col sm={12} md={6} className={''}  >
                                <H1text size={'36'} lineHeight={'36'} fontWeight={'600'} className="text-left" >{'What are people saying'}</H1text>
                            </Col>
                            <Col sm={12} md={6} className={'mt-3 mt-md-0 d-flex justify-content-start justify-content-md-end'}  >
                                <Button variant="outline-dark" className=" " style={{ border: '1px solid #000000', fontWeight: '600', borderRadius: '12px', padding: '12px 24px' }}> <Ptext size={'16'} lineHeight={'24'} fontWeight={'600'} marginTop="0" >{'All reviews'}</Ptext></Button>
                            </Col>
                        </Row>
                    </Col>
                    <CustomCarousel
                        slidesToShow={3}
                        useFor={"Feedback"}
                    />
                </Container>
            </Box>



        </div>
    )
}

export default ProductDetails
