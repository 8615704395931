import {COMPANY_REVIEWS} from "./constant";

const companyReviewsCallApi = (type , data , external={})=>{
    
    switch(type){
        case COMPANY_REVIEWS:
            return {
                type:type,
                data:data,
                external:external
            }
            default:
                return{
                    type:type
                }
    }
        
}
export default companyReviewsCallApi;