import { Box } from "@mui/material";
import CustomCarousel from "components/CustomCarousel/CustomCarousel";
import H1text from "components/H1text/H1text";
import Ptext from "components/Ptext/Ptext";
import { React, useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import companyReviewsCallApi from "store/withoutToken/companyReviews/action";
import { COMPANY_REVIEWS } from "store/withoutToken/companyReviews/constant";
import riderProfileCallApi from "store/withoutToken/riderProfile/action";
import { RIDER_PROFILE } from "store/withoutToken/riderProfile/constant";
import styled from "styled-components";


const Reviews = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const reviews = [
        { id: 1, value: "Reviews from companies" },
        { id: 2, value: "Rider profiles" },
        { id: 3, value: "What are people saying" }
    ]
    
    const riderProfile = useSelector((state) => state?.riderProfiletReducer?.payload?.data?.OTP);
    const companyReviews = useSelector((state) => state?.companyReviewsReducer?.payload?.data);
    useEffect(()=>{
        getRiderProfile();
        getCompanyReviews();
    },[])
    
     // rider profile 
     const getRiderProfile = () => {
        dispatch(riderProfileCallApi(RIDER_PROFILE, { key: "" }, {
            run: true,
            handleFunction: (res) => { }
        }));
    };
     // rider profile 
     const getCompanyReviews = () => {
        dispatch(companyReviewsCallApi(COMPANY_REVIEWS, {
            run: true,
            handleFunction: (res) => { }
        }));
    };

    const handleClick = (data) => {
        if(data?.id==2){
            navigate('/rider-profile');
        }
       if(data?.id==1){
            navigate('/company-review');
        }
    }
    
    return (
        <div>
            {reviews && reviews.map((data, index) => (
                <Box
                sx={{
                    padding: {
                        xs: '24px 0',
                        sm: '24px 0',
                        md: '48px 0',
                        lg: '96px 0'
                    }
                }}
                 key={index} style={{ background: data?.value === "Rider profiles" ? "#FAFAFA" : '#ffffff' }}>
                    <Container>
                        <Row className="align-items-center ">

                            <Col md={12} >
                                <div className=" d-flex justify-content-between align-items-start flex-column flex-md-row align-items-md-between">
                                    <H1text size={'36'} lineHeight={'36'} fontWeight={'600'}  className="text-left" >{data?.value}</H1text>
                                    <Button variant="outline-dark" className="mt-3 mt-md-0 " style={{border:'1px solid #000000',fontWeight:'600', borderRadius:'12px', padding:'12px 24px'}} onClick={()=>handleClick(data)}> <Ptext size={'16'} lineHeight={'24'} fontWeight={'600'}  marginTop="0" >{data?.id==2?'All profile':'All reviews'}</Ptext></Button>
                                </div>
                            </Col>
                           
                            <Col className="carouselDiv">
                            <Box
                             sx={{
                                marginTop: {
                                    xs: '12px',
                                    sm: '12px',
                                    md: '24px',
                                    lg: '48px'
                                }
                            }}
                            >
                                    {data?.id==1 &&
                                    <CustomCarousel 
                                    slidesToShow={3}
                                    useFor={"Reviews"}
                                    data={companyReviews}
                                    />}
                                    {data?.id==2 && 
                                    <CustomCarousel 
                                    slidesToShow={4}
                                    useFor={"Rider"}
                                    data={riderProfile}
                                    />}
                                    {data?.id==3 && 
                                    <CustomCarousel 
                                    slidesToShow={3}
                                    useFor={"Feedback"}
                                    />}
                            </Box>
                            </Col>
                        </Row>
                    </Container>
                </Box>
            ))}
        </div>

    )
}
export default Reviews