import H1text from 'components/H1text/H1text'
import Ptext from 'components/Ptext/Ptext'
import React from 'react'
import styled from 'styled-components'

const WrapperDiv = styled.div`
height:504px;
background: url(${props => props.image});

background-size: cover;
background-repeat: no-repeat;
background-position: center;
border-radius:16px;
position:
`

const FullWidthCard = (props) => {
  return (
    <WrapperDiv {...props}>
      <H1text size={'36'} lineHeight={'36'} fontWeight={'600'}>
            Power
            </H1text>
            <Ptext size={'14'} lineHeight={'24'} fontWeight={'500'} style={{maxWidth:'350px'}}>
            Our e-scooters run off premium batteries that charge fast and last a long time. You can count on reliable performance, day in and day out
            </Ptext>
    </WrapperDiv>
  )
}

export default FullWidthCard
