import { takeLatest,put } from "redux-saga/effects";
import { getMethodWithToken } from "services/apiServices";
import url from 'services/urls.json';
import { ACTIVE_SUBSCRIPTIONS , ACTIVE_SUBSCRIPTIONS_ERROR,ACTIVE_SUBSCRIPTIONS_SUCCESS } from "./constant";
import { ClearTokenRedirectLogin } from "context/context";

function* activeSubscriptionsApi(action){
    try {
        const response=yield getMethodWithToken(url.activeSubscriptions)
            if(response?.status === 200 || response?.status === 201){
                yield put({type:ACTIVE_SUBSCRIPTIONS_SUCCESS , payload:response})
                if(action.external && action.external.run === true){
                    action.external.handleFunction(response)
                }
                if(action.external && action.external.toast === true){
                    action.external.handleToast(response)
                }
            }
            else {
                yield put({type:ACTIVE_SUBSCRIPTIONS_ERROR , payload:response})

                if(response?.status === 401 || response?.status === 403){
                    ClearTokenRedirectLogin(action.external.navigate)
                  }

                if(action.external && action.external.toast === true){
                    action.external.handleToast()
                }
                
            }
    }
    catch(error){
        yield put({type:ACTIVE_SUBSCRIPTIONS_ERROR , payload:error})
    }
}

function* activeSubscriptionsSaga(){
    yield takeLatest(ACTIVE_SUBSCRIPTIONS , activeSubscriptionsApi)
}

export default activeSubscriptionsSaga