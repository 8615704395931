import { PLANS_PRODUCT, PLANS_PRODUCT_ERROR, PLANS_PRODUCT_SUCCESS } from "./constant"


const plansProductReducer = (state={},action)=>{

    switch(action.type){
        case PLANS_PRODUCT:{
            return {
                ...state,
                loading:true,
                error:''
            };
        }
        case PLANS_PRODUCT_ERROR:{
            return {
                ...state,
                loading:false,
                type:PLANS_PRODUCT_ERROR,
                error:action.payload
            };
        }
        case PLANS_PRODUCT_SUCCESS:{
            return {
                ...state,
                type:PLANS_PRODUCT_SUCCESS,
                loading:false,
                payload:action.payload,
                error:''
            };
        }
        default :
        return state;
    }
}
export default plansProductReducer