import logo from './logo.svg';
import './App.css';
import Navbar from './components/common/Navbar/Navbar';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from '../src/routes/Rout';
import Footer from 'components/common/footer/footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Ensure this import is present
import { Provider } from "react-redux";
import store from "store";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


const stripePromise = loadStripe('pk_test_51NiXijSGks82zzGkWSNy0lVC8AMZTIFj4bKEIHCTm6pIoCmnNVhhLRax18nG1bXJ7fpagcjNTbDkNDsDzDpkHgt600uMRRVmbT');

function App() {
  const options={
    clientSecret: 'sk_test_51NiXijSGks82zzGkgFRQCXbhU9IEGBPo5VcIGJj9UuC9U9muXv1pLwZA8iGn0LrY8NiJULToMdUlZOkPA3qtpPwg00F6TULlzv',
  }
  return (
    <Provider store={store}>
    <Router>
    <Elements stripe={stripePromise}>
      <div className="App" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', overflowY: 'hidden',overflowX: 'hidden', background:'transparent' }}>
        <Navbar />
        <main style={{ flex: '1', overflow:'hidden' }} className=" p-md-0">
        {/* , display:'flex', alignItems:'center', justifyContent:'center' */}
          <AppRoutes />
          <ToastContainer />
        </main>
        <Footer />
      </div>
      </Elements>
    </Router>
    </Provider>
  );
}

export default App;




  