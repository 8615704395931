import { DROPDOWN_MASTER, DROPDOWN_MASTER_ERROR, DROPDOWN_MASTER_SUCCESS } from "./constant"


const dropdownMasterReducer = (state={},action)=>{

    switch(action.type){
        case DROPDOWN_MASTER:{
            return {
                ...state,
                loading:true,
                error:''
            };
        }
        case DROPDOWN_MASTER_ERROR:{
            return {
                ...state,
                loading:false,
                type:DROPDOWN_MASTER_ERROR,
                error:action.payload
            };
        }
        case DROPDOWN_MASTER_SUCCESS:{
            return {
                ...state,
                type:DROPDOWN_MASTER_SUCCESS,
                loading:false,
                payload:action.payload,
                error:''
            };
        }
        default :
        return state;
    }
}
export default dropdownMasterReducer