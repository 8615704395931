import { GET_FAVOURITE, GET_FAVOURITE_ERROR, GET_FAVOURITE_SUCCESS } from "./constant"


const getFavouriteReducer = (state={},action)=>{

    switch(action.type){
        case GET_FAVOURITE:{
            return {
                ...state,
                loading:true,
                error:''
            };
        }
        case GET_FAVOURITE_ERROR:{
            return {
                ...state,
                loading:false,
                type:GET_FAVOURITE_ERROR,
                error:action.payload
            };
        }
        case GET_FAVOURITE_SUCCESS:{
            return {
                ...state,
                type:GET_FAVOURITE_SUCCESS,
                loading:false,
                payload:action.payload,
                error:''
            };
        }
        default :
        return state;
    }
}
export default getFavouriteReducer;