import { Tab, Tabs } from '@mui/material'
import Ptext from 'components/Ptext/Ptext';
import React from 'react'
import styled from 'styled-components';

const WrapperDiv = styled.div`
.MuiTabs-flexContainer{
    background: #f6f6f6 !important;
    border-radius:10px;
}
.Mui-selected{
    border:none;
    color:#ffffff !important;
    background:#000000;
    border-radius:10px;
    margin:2px;
    
    
}
.MuiButtonBase-root{
    width:${props=>props?.width?props?.width:'178px'} ;
    // text-transform: capitalize;
}
.MuiTabs-indicator{
    display:none;
}
@media screen and (max-width:450px){
    .MuiButtonBase-root{
        width:140px;
        // text-transform: capitalize;
    }
}
`

export default function TabSwitch(props) {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
      setValue(newValue);
      props.tranferData(value);
    };
    
    return (
        <WrapperDiv {...props}>
        <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons={false}
            aria-label="scrollable prevent tabs example"
        >
            { props?.tabs.map((item, index)=>(
            <Tab key={index} label={<Ptext textTransform={'capitalize'} size='16' lineHeight='24' fontWeight='600' marginTop={'0'}>{item?.type}</Ptext>} />
            ))}
            {/* <Tab label={<Ptext textTransform={'capitalize'} size='16' lineHeight='24' fontWeight='600' marginTop={'0'}>{props?.itemTwo}</Ptext>} /> */}
        </Tabs>
        </WrapperDiv>
    )
}
