import {ADD_FAVOURITE} from "./constant";

const addFavouriteCallApi = (type , data , external={})=>{
    switch(type){
        case ADD_FAVOURITE:
            return {
                type:type,
                data:data,
                external:external
            }
            default:
                return{
                    type:type
                }
    }
        
}
export default addFavouriteCallApi;