import React from "react";
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Ptext from "components/Ptext/Ptext";
import { Box } from "@mui/material";
import switchIcon from 'assets/svg/switch.svg'

// Wrapper for the TextField
const WrapperTextField = styled(TextField)`
  .MuiPaper-root {
    padding: 0 !important;
  }
  .MuiInputBase-root {
    border: 1px solid #dfdfdf; 
    border-radius: 12px; 
    height: 48px;
    margin-top:0;
    overflow:hidden;
  }
  .MuiSelect-select {
    padding: 14px;
    padding-top:28px !important;
    border-radius: 12px !important; 
    font-family: 'Manrope';
    max-width:75vw;
  }
  .MuiFormLabel-root {
    margin-top:8px !important;
    color:#000000;
  }
  .MuiInput-underline:before,
  .MuiInput-underline:after {
    border-bottom: none !important; 
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none !important; 
  }
  svg {
    margin-right: 12px;
    color:#000000;
  }
  .MuiFormLabel-root {
    padding-left:16px;
    margin-top:-5px !important;
  }
  [data-shrink="false"] {
    padding-left:20px;
    color: #808080 !important;
    font-size:14px;
    line-height:20px;
    font-weight:500;
  }
  [data-shrink="true"] {
    padding-left:20px;
    margin-top: 9px !important;
    color: #000000 !important;
    font-size:14px;
    line-height:20px;
    font-weight:500;
    font-family: 'Manrope';
  }
  .MuiList-padding{
    padding:0 !important;
  }
`;

const StyledFormControl = styled(FormControl)`
  width: 100% !important;
`;

function PlaceholderInput({ containerProps, multiSelect, ...props }) {
  const handleChange = (event) => {
    const { value } = event.target;
    if (multiSelect) {
      props.onChange(value); // For multi-select, value will be an array
    } else {
      props.onChange(value); // For single select, value will be a string
    }
  };

  return (
    <StyledFormControl fullWidth sx={{ all: 'unset' }}>
      <WrapperTextField
        select
        id="standard-required"
        variant="standard"
        fullWidth={true}
        value={multiSelect ? (props?.value || []) : (props?.value || "")}
        onChange={handleChange}
        // InputLabelProps={{
        //   shrink: true ,
        // }}
        SelectProps={{
          multiple: multiSelect,
          IconComponent: KeyboardArrowDownIcon,
          renderValue: (selected) =>
            multiSelect
              ? selected.map((item) => {
                const selectedOption = props?.data.find(
                  (option) => option?.slug === item || option?.label === item || option?.id === item
                );
                return selectedOption?.name || selectedOption?.value || selectedOption?.title || item;
              }).join(", ")
              : props?.data.find(
                (option) => option?.slug === selected || option?.label === selected || option?.id === selected
              )?.title || selected,
        }}
        {...props}
      >
        {/* "Clear selection" option */}
        {!multiSelect && props?.noneOption &&  (
          <MenuItem value="">
            <Ptext size={'14'} lineHeight={'20'} fontWeight={'500'} marginTop={'0'}>
              {props?.clearText || 'None'}
            </Ptext>
          </MenuItem>
        )}

        {/* Options from data */}
        {props?.data?.length > 0 ? (
          props.data.map((k, i) => (
            <MenuItem
              key={i}
              value={multiSelect ? (k?.slug || k?.label || k?.id) : (k?.slug || k?.label || k?.id || k?.printable_name)} // Use actual value for single select

            >
              <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
                <Box display='flex'>
                  <Ptext size={'14'} lineHeight={'20'} fontWeight={'500'} marginTop={'0'}>
                    {k?.printable_name ||k?.name || k?.value || k?.title }
                  </Ptext>
                  {/* / &nbsp;<Ptext size={'14'} lineHeight={'20'} fontWeight={'700'} marginTop={'0'}>${k?.price_incl_tax}</Ptext> */}
                </Box>
                {multiSelect && (
                  <Checkbox
                    checked={props?.value?.includes(k?.slug || k?.label || k?.id)}
                    onChange={() => {
                      const newValue = props?.value?.includes(k?.slug || k?.label || k?.id)
                        ? props?.value?.length > 0 && props?.value.filter((val) => val !== (k?.slug || k?.label || k?.id))
                        : [...props?.value, k?.slug || k?.label || k?.id];
                      //  props.onChange(newValue);
                    }}
                    icon={<Box height='20px' width='20px' borderRadius='8px' border='1px solid #e4e4e4'></Box>} // Unchecked icon
                    checkedIcon={<img src={switchIcon}></img>} // Checked icon
                  />
                )}
              </Box>
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled value="">
            No options available
          </MenuItem>
        )}
      </WrapperTextField>
    </StyledFormControl>
  );
}

PlaceholderInput.defaultProps = {
  height: 56,
  inputPadding: "14px",
  fontFamily: 'Manrope',
  placeholder: 'Select an option', // Set a default placeholder
  clearText: 'None', // Add default "clear" text
  data: [],
  multiSelect: false, // Default to single select
};

export default PlaceholderInput;
