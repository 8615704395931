import { CART, CART_ERROR, CART_SUCCESS } from "./constant"


const cartReducer = (state={},action)=>{

    switch(action.type){
        case CART:{
            return {
                ...state,
                loading:true,
                error:''
            };
        }
        case CART_ERROR:{
            return {
                ...state,
                loading:false,
                type:CART_ERROR,
                error:action.payload
            };
        }
        case CART_SUCCESS:{
            return {
                ...state,
                type:CART_SUCCESS,
                loading:false,
                payload:action.payload,
                error:''
            };
        }
        default :
        return state;
    }
}
export default cartReducer