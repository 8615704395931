import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';
import { SvgIcon } from '@mui/material';

// Styled wrapper for custom stepper
const WrapperDiv = styled.div`
  .MuiStep-root:nth-of-type(1) .MuiStepLabel-root {
    display: flex !important;
    align-items: flex-start !important; /* Aligns first step to start */
  }
  .MuiStepLabel-label{
    font-size:14px;
    line-height:20px;
    font-weight:500;
    font-family:'Manrope';
  }
  .MuiStep-root:nth-of-type(1) .MuiStepLabel-labelContainer {
    display: flex !important;
    align-items: flex-start !important; /* Aligns first step to start */
  }
  .MuiStep-root:last-of-type .MuiStepLabel-root {
    display: flex !important;
    align-items: flex-end !important; /* Aligns last step to end */
  }
  .MuiStep-root:last-of-type .MuiStepLabel-labelContainer {
    display: flex !important;
    align-items: flex-end !important; /* Aligns first step to start */
    justify-content: end;
  }
  .MuiStep-root .MuiStepConnector-root{
    top:6px !important;
  }
  .MuiStep-root .MuiStepConnector-line{
    border-color:#c9c9c9 !important;
  }
  .MuiStep-root:nth-of-type(2) .MuiStepConnector-root{
    left: calc(-95% + 20px);
    right: calc(50% + 20px);
  }
  .MuiStep-root:nth-of-type(3) .MuiStepConnector-root{
    left: calc(-50% + 20px);
    right: calc(5% + 20px);
  }
`;

// Custom StepIcon component
const CustomStepIcon = muiStyled(({ active, completed, icon }) => {
  return (
    <Box style={{ backgroundColor: completed ? '#1d1d1d' : active ? '#1d1d1d' : '#ffffff', height:'14px', width:'14px', borderRadius:'50%',display:'flex', justifyContent:'center', alignItems:'center'}}>
    <SvgIcon
      sx={{
        backgroundColor: completed ? '#1d1d1d' : active ? '#1d1d1d' : '#c9c9c9',
        color: 'white',
        borderRadius: '50%',
        width: 8,
        height: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: completed ? '2px solid #fff' : active ? '2px solid #fff' : 'none',
        padding: completed ? '4px' : active ? '4px' : '4px',
        
      }}
    >
      {icon}
    </SvgIcon>
    </Box>
  );
})(({ theme }) => ({
  '&.MuiStepIcon-active': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  '&.MuiStepIcon-completed': {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
  },
}));



export default function CustomStepper({ steps, activeStep }) {
  return (
    <WrapperDiv>
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                StepIconComponent={(props) => (
                  <CustomStepIcon {...props} icon={index + 1} />
                )}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </WrapperDiv>
  );
}
