import * as React from 'react';
import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import CheckIcon from '@mui/icons-material/Check'; // Import a custom icon
import styled from 'styled-components';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Box, FormControlLabel } from '@mui/material';
import switchIcon from 'assets/svg/switch.svg'

const WrapperDiv = styled.div`
  .MuiButtonBase-root {
    padding: 0 !important;
  }
  .MuiTypography-root {
    margin-left:12px;
  }
  .MuiFormControlLabel-root{
    margin-left:0;
    padding: 0 !important;
  }
`;

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function CustomCheckbox(props) {
  return (
    <WrapperDiv>
      <FormControlLabel
        label={props?.label}
        control={
          <Checkbox
            {...label}
            checked={props?.checked}
            onChange={props?.onChange}
            icon={<Box height='20px' width='20px' borderRadius='8px' border='1px solid #e4e4e4'></Box>} // Unchecked icon
            checkedIcon={<img src={switchIcon}></img>} // Checked icon
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: 28,
              },
            }}
          />
        }
      />

    </WrapperDiv>
  );
}
