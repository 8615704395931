import { RIDER_PROFILE, RIDER_PROFILE_ERROR, RIDER_PROFILE_SUCCESS } from "./constant"


const riderProfiletReducer = (state={},action)=>{

    switch(action.type){
        case RIDER_PROFILE:{
            return {
                ...state,
                loading:true,
                error:''
            };
        }
        case RIDER_PROFILE_ERROR:{
            return {
                ...state,
                loading:false,
                type:RIDER_PROFILE_ERROR,
                error:action.payload
            };
        }
        case RIDER_PROFILE_SUCCESS:{
            return {
                ...state,
                type:RIDER_PROFILE_SUCCESS,
                loading:false,
                payload:action.payload,
                error:''
            };
        }
        default :
        return state;
    }
}
export default riderProfiletReducer