import { USER } from "./constant";

const userCallApi=(type , data , external = {})=>{

    switch(type){
        case USER:
            return {
                type:type,
                data:data,
                external:external
            }
        default:
            return {
                type:type
            }    
    }

}
export default userCallApi;