import { ANONYMOUS, ANONYMOUS_ERROR, ANONYMOUS_SUCCESS } from "./constant"


const anonymousReducer = (state={},action)=>{

    switch(action.type){
        case ANONYMOUS:{
            return {
                ...state,
                loading:true,
                error:''
            };
        }
        case ANONYMOUS_ERROR:{
            return {
                ...state,
                loading:false,
                type:ANONYMOUS_ERROR,
                error:action.payload
            };
        }
        case ANONYMOUS_SUCCESS:{
            return {
                ...state,
                type:ANONYMOUS_SUCCESS,
                loading:false,
                payload:action.payload,
                error:''
            };
        }
        default :
        return state;
    }
}
export default anonymousReducer;