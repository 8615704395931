import { takeLatest,put } from "redux-saga/effects";
import url from 'services/urls.json';
import { postMethodWithToken } from "services/apiServices";
import {ADD_FAVOURITE , ADD_FAVOURITE_ERROR , ADD_FAVOURITE_SUCCESS} from './constant';
import { ClearTokenRedirectLogin } from "context/context";

function* postAddFavouriteApi(action) {
 
    try {
        const response = yield postMethodWithToken(url.favourite,action.data);
        if(response?.status === 200 || response?.status === 201){
            yield put({type:ADD_FAVOURITE_SUCCESS , payload : response});
            
            if (action.external && action.external.run === true) {
                action.external.handleFunction(response);
            }
        }
        else {
            yield put({type:ADD_FAVOURITE_ERROR , payload : response});
            if(response?.status === 401 || response?.status === 403){
              ClearTokenRedirectLogin(action.external.navigate)
            }
            if(response?.status === 406){
                action.external.handleError(response);
              }
            if (action.external && action.external.toast) {
                action.external.handleToastError(response)
            }
        }
    }
    catch(error){
        yield put({type:ADD_FAVOURITE_ERROR,payload:error})
    }
}

function* addFavouriteSaga(){
    yield takeLatest(ADD_FAVOURITE , postAddFavouriteApi)
}

export default addFavouriteSaga;
