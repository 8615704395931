// CommonButton.js
import React from 'react';
import styled from 'styled-components';

// Styled button component
const Button = styled.button`
  background-color: ${(props) => props.background};
  color: ${(props) => props.color};
  margin-top: ${(props) => props.marginTop};
  padding: ${(props) => props.padding? props.padding:'12px 20px'};
  border: ${(props) => props.border};
  cursor: pointer;
  border-radius: 12px;
  font-size: 16px;
  line-height:24px;
  font-weight:  ${(props) => props.fontWeight?props.fontWeight:'600'};
  font-family:'Manrope';
  display:flex;
  justify-content:center;
//   transition: background-color 0.3s ease;
  width:${(props) => props.width?props.width:'100%'};
  height:${(props) => props.height?props.height:''};
  
//   &:hover {
//     background-color: ${(props) => props.hoverBackground};
//   }
`;

const BasicButton = (props) => {
    return (
        <Button {...props}>
          {props.MUIicon?props.MUIicon:''}
           {props?.icon && <img src={props.icon} style={{width: props.iconWidth?props.iconWidth:'52px', marginRight:props.iconMarginRight?props.iconMarginRight:'0px'}} />} 
            {props?.text}
        </Button>
    );
};

// Adding default props
BasicButton.defaultProps = {
    text: 'Button',
    background: '#f6f6f6',
    color: '#1d1d1d',
    marginTop: '0px',
    hoverBackground: '#e0e0e0',
    // onClick: () => alert('Default Button Clicked'),
};

export default BasicButton;
