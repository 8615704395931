import { takeLatest,put } from "redux-saga/effects";
import { getMethodWithToken } from "services/apiServices";
import url from 'services/urls.json';
import { PLANS_PRODUCT , PLANS_PRODUCT_ERROR,PLANS_PRODUCT_SUCCESS } from "./constant";
import { ClearTokenRedirectLogin } from "context/context";

function* plansProductApi(action){
    try {
        const { key } = action?.data;

        // Construct the URL with the dynamic id
        const urlWithId = `${url.plansProduct}${key}/`;
        const response=yield getMethodWithToken(urlWithId)
            if(response?.status === 200 || response?.status === 201){
                yield put({type:PLANS_PRODUCT_SUCCESS , payload:response})
                if(action.external && action.external.run === true){
                    action.external.handleFunction(response)
                }
                if(action.external && action.external.toast === true){
                    action.external.handleToast(response)
                }
            }
            else {
                yield put({type:PLANS_PRODUCT_ERROR , payload:response})

                if(response?.status === 401 || response?.status === 403){
                    ClearTokenRedirectLogin(action.external.navigate)
                  }
                if(response?.status === 404 || response?.status === 500){
                    action.external.handleError(response);
                  }
                  
                if(action.external && action.external.toast === true){
                    action.external.handleToast()
                }
               
            }
    }
    catch(error){
        yield put({type:PLANS_PRODUCT_ERROR , payload:error})
    }
}

function* plansProductSaga(){
    yield takeLatest(PLANS_PRODUCT , plansProductApi)
}

export default plansProductSaga