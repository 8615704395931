import { SIGN_IN } from "./constant";

const signCallApi = (type, data, external = {}) => {
    switch (type) {
        case SIGN_IN:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type
            }
    }

}
export default signCallApi;