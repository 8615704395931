import { takeLatest,put } from "redux-saga/effects";
import { getMethodWithToken } from "services/apiServices";
import url from 'services/urls.json';
import { PAYMENT_INTENT , PAYMENT_INTENT_ERROR,PAYMENT_INTENT_SUCCESS } from "./constant";
import { ClearTokenRedirectLogin } from "context/context";

function* paymentIntentApi(action){
    try {
        const response=yield getMethodWithToken(url.paymentIntent)
            if(response?.status === 200 || response?.status === 201){
                yield put({type:PAYMENT_INTENT_SUCCESS , payload:response})
                if(action.external && action.external.run === true){
                    action.external.handleFunction(response)
                }
                if(action.external && action.external.toast === true){
                    action.external.handleToast(response)
                }
            }
            else {
                yield put({type:PAYMENT_INTENT_ERROR , payload:response})

                if(response?.status === 401 || response?.status === 403){
                    ClearTokenRedirectLogin(action.external.navigate)
                  }

                if(action.external && action.external.toast === true){
                    action.external.handleToast()
                }
                
            }
    }
    catch(error){
        yield put({type:PAYMENT_INTENT_ERROR , payload:error})
    }
}

function* paymentIntentSaga(){
    yield takeLatest(PAYMENT_INTENT , paymentIntentApi)
}

export default paymentIntentSaga