import { takeLatest,put } from "redux-saga/effects";
import { getMethodWithToken } from "services/apiServices";
import url from 'services/urls.json';
import { ORDER_HISTORY , ORDER_HISTORY_ERROR,ORDER_HISTORY_SUCCESS } from "./constant";
import { ClearTokenRedirectLogin } from "context/context";

function* orderHistoryApi(action){
    try {
        const response=yield getMethodWithToken(url.orderHistory)
            if(response?.status === 200 || response?.status === 201){
                yield put({type:ORDER_HISTORY_SUCCESS , payload:response})
                if(action.external && action.external.run === true){
                    action.external.handleFunction(response)
                }
                if(action.external && action.external.toast === true){
                    action.external.handleToast(response)
                }
            }
            else {
                yield put({type:ORDER_HISTORY_ERROR , payload:response})

                if(response?.status === 401 || response?.status === 403){
                    ClearTokenRedirectLogin(action.external.navigate)
                  }

                if(action.external && action.external.toast === true){
                    action.external.handleToast()
                }
                
            }
    }
    catch(error){
        yield put({type:ORDER_HISTORY_ERROR , payload:error})
    }
}

function* orderHistorySaga(){
    yield takeLatest(ORDER_HISTORY , orderHistoryApi)
}

export default orderHistorySaga