import { takeLatest,put } from "redux-saga/effects";
import { getMethod, getMethodWithToken } from "services/apiServices";
import url from 'services/urls.json';
import { RIDER_PROFILE , RIDER_PROFILE_ERROR,RIDER_PROFILE_SUCCESS } from "./constant";
import { ClearTokenRedirectLogin } from "context/context";

function* riderProfiletApi(action){
    try {
        const { key } = action?.data;

        // Construct the URL with the dynamic id
        const urlWithId = `${url.riderProfile}${key}`;
        const response=yield getMethod(urlWithId)
            if(response?.status === 200 || response?.status === 201){
                yield put({type:RIDER_PROFILE_SUCCESS , payload:response})
                if(action.external && action.external.run === true){
                    action.external.handleFunction(response)
                }
                if(action.external && action.external.toast === true){
                    action.external.handleToast(response)
                }
            }
            else {
                yield put({type:RIDER_PROFILE_ERROR , payload:response})

                if(response?.status === 401 || response?.status === 403){
                    ClearTokenRedirectLogin(action.external.navigate)
                  }

                if(action.external && action.external.toast === true){
                    action.external.handleToast(response)
                }
                
            }
    }
    catch(error){
        yield put({type:RIDER_PROFILE_ERROR , payload:error})
    }
}

function* riderProfiletSaga(){
    yield takeLatest(RIDER_PROFILE , riderProfiletApi)
}

export default riderProfiletSaga