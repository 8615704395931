import { COMMON_DETAILS, PRODUCT_ID, IS_DRAWER } from "./constant";

export const commonCallApi = (type, data, external = {}) => {
    switch (type) {
        case COMMON_DETAILS:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
}

export const productID = (type, data, external = {}) => {
    switch (type) {
        case PRODUCT_ID:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
}
export const isDrawer = (type, data, external = {}) => {
    switch (type) {
        case IS_DRAWER:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
}