import { Box } from '@mui/material'
import React from 'react'

const Rating = (props) => {
    return (
        <>
            <Box display={'flex'} alignItems={'center'}>
                {Array.from({ length: 5 }).map((_, index) => (
                    <Box
                        key={index}
                        backgroundColor={index < parseInt(props.rating, 10) ? "#4579F5" : (index === 4 && props.rating === '4' ? "#e8e8e8" : "#ccc")}
                        height={'6px'}
                        width={"16px"}
                        borderRadius={'24px'}
                        marginLeft={'4px'}
                    />
                ))}
            </Box>

        </>
    )
}

export default Rating
