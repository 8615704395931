import { Box } from '@mui/system'
import H1text from 'components/H1text/H1text'
import Ptext from 'components/Ptext/Ptext'
import React from 'react'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'


const WrapperDiv = styled.div`
font-family:'Manrope';
height:${props => props.height};
width:100%;
border-radius:24px;
padding:32px;
position:relative;
color:${props => props.color};

background: url(${props => props.image});
background-size: cover;
background-repeat: no-repeat;
background-position: center;
border-radius:${props => props.borderRadius};

.headData{
    bottom:${props=>props.bottom};
    right:${props=>props.right};
}
.heading{
font-size:${props=>props.headingSize};
line-height:32px;
font-weight:600;
}
.subHeading{
    font-size:${props=>props.subHeadingSize};
    line-height:24px;
    font-weight:500;
    max-width:${props=>props.subHeadingMaxWidth};
    margin-top:${props=>props.subHeadingMarginTop};
}
@media screen and (max-width:567px){
    .headData{
       padding:20px;
       width: 100%;
       left: 0;
       right: 0;
    }
    .subHeading{
        max-width:100%;
       
    }
}
`

const ImageTextCard = (props) => {
    return (
        <WrapperDiv {...props}>
             <Container className="p-0" >
            <Box className="headData" position={'absolute'}>
            <Box className="heading" >
            {props.heading}
            </Box>
            <Box className="subHeading">
            {props.subHeading}
            </Box>
            </Box>
             </Container>
        </WrapperDiv>
    )
}
ImageTextCard.defaultProps = {
    borderRadius:"16px",
    background:'grey',
    height:'400px',
    color:'#ffffff',

    headingSize:'24px',
    subHeadingSize:'14px',
    bottom:"32px",
    subHeadingMarginTop:"12px",
    subHeadingMaxWidth:"350px"
}

export default ImageTextCard
