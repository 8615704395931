import React from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';

const WrapperH1 = styled.h1`
    margin-bottom: ${(props) => props.marginBottom + 'px'};
    font-size: ${(props) => props.size + 'px'};
    font-weight: ${(props) => props.fontWeight} !important;
    font-family: ${(props) => props.fontFamily};
    font-style: ${(props) => props.fontStyle};
    line-height: ${(props) => props.lineHeight + 'px'};
    color: ${(props) => props.color};
    margin-top: ${(props) => props.marginTop + 'px'};
    background: ${(props) => props.theme[props.bgColor]};
    border-radius: ${(props) => props.borderRadius + 'px'};
    padding-top: ${(props) => props.paddingTop + 'px'};
    padding-bottom: ${(props) => props.paddingBottom + 'px'};
    padding-left: ${(props) => props.paddingLeft + 'px'};
    padding-right: ${(props) => props.paddingRight + 'px'};
    border-bottom: ${(props) => props.borderBottom};
    @media screen and (max-width:576px){
        font-size: ${(props) => props.size/1.5 + 'px'};
    }
`;

function H1text({
    children,
    marginTop = 10,
    marginBottom = 0,
    size = 64,
    fontFamily = 'Manrope',
    fontWeight = 800,
    fontStyle = 'normal',
    lineHeight = 96,
    color = 'lightDark',
    borderBottom = 'none',
    ...rest
}) {
    return (
        <WrapperH1
            marginTop={marginTop}
            marginBottom={marginBottom}
            size={size}
            fontFamily={fontFamily}
            fontWeight={fontWeight}
            fontStyle={fontStyle}
            lineHeight={lineHeight}
            color={color}
            borderBottom={borderBottom}
            {...rest}
        >
            {children}
        </WrapperH1>
    );
}

H1text.propTypes = {
    color: string,
};

export default H1text;
