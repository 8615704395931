import React from 'react'
import { Col, Row } from 'react-bootstrap'

const RentModal = (props) => {
    console.log("dsfd",props)
  return (
    <div>
       <Row className="g-3">
                  {props?.plansProduct?.data?.length > 0 && props?.plansProduct?.data.map((data, index) => (
                    <Col key={index} md={6} lg={4} >
                      <div>
                      <stripe-pricing-table 
                        pricing-table-id={data?.price_table_id}
                        publishable-key="pk_test_51NiXijSGks82zzGkWSNy0lVC8AMZTIFj4bKEIHCTm6pIoCmnNVhhLRax18nG1bXJ7fpagcjNTbDkNDsDzDpkHgt600uMRRVmbT"
                        customer-session-client-secret={props?.plansProduct?.seddionid}
                        >
                      </stripe-pricing-table>
                      </div>
                    </Col>
                  ))}
                </Row>
    </div>
  )
}

export default RentModal
