import { Box } from "@mui/material";
import H1text from "components/H1text/H1text";
import Ptext from "components/Ptext/Ptext";
import { React, useState, useEffect } from "react";
import { Container, Row, Col,Button } from "react-bootstrap";

const ModerApp = () =>{


    return (
        <Box
        sx={{
            paddingBottom: {
                xs: '12px ',
                sm: '24px ',
                md: '48px ',
                lg: '96px '
            }
        }}
         style={{fontFamily:"Manrope, sans-serif"}}>
              <Container >
                        <Row className="mt-5 align-items-center" style={{background:'#ffffff', borderRadius:'24px'}}>
                           
                            <Col md={6} className="text-left">
                            <img src="https://metrorides.theclientdemos.com/assets/images/a5.png" className="w-100"/>
                               </Col>
                            <Col md={6} className="text-left d-flex justify-content-center" >
                                <div style={{maxWidth:'309px'}}>
                            <H1text size={'36'} lineHeight={'36'} fontWeight={'600'} style={{fontWeight:'600'}}>A convenient modern App for your scooter </H1text>
                              <Button variant="dark" className="rounded-5" style={{marginTop:'36px', padding:'12px 24px',marginBottom:'30px'}}>
                                <Ptext size={'16'} lineHeight={'24'} fontWeight={'600'} marginTop={'0'} >
                                Coming soon...
                                </Ptext>
                                </Button>
                                </div>

                            </Col>

                        </Row>
                    </Container>
        </Box>
    )
}
export default ModerApp