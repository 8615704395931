
import { USER,USER_ERROR,USER_SUCCESS } from "./constant";

const userReducer=(state={},action)=>{

    switch(action.type){
        case USER:
            return {
                ...state,
                loading:true,
                error:''
            }
            case USER_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload,
                type:USER_ERROR
            }
            case USER_SUCCESS:
            return {
                ...state,
                loading:false,
                error:'',
                payload:action.payload,
                type:USER_SUCCESS
            }
            default:
                return state;
    }
}
export default userReducer;