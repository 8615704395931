import {ORDER_DETAILS} from "./constant";

const orderDetailsCallApi = (type , data , external={})=>{
    
    switch(type){
        case ORDER_DETAILS:
            return {
                type:type,
                data:data,
                external:external
            }
            default:
                return{
                    type:type
                }
    }
        
}
export default orderDetailsCallApi