import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const Wrapper = styled.p`
	margin-bottom: ${(props) => `${props.marginBottom}px`};
	font-size: ${(props) => `${props.size}px`};
	color: ${(props) => props.color};
	margin-top: ${(props) => `${props.marginTop}px`};
	margin-left: ${(props) => `${props.marginLeft}px`};
	font-family: ${(props) => props.fontFamily};
	font-weight: ${(props) => props.fontWeight};
	line-height: ${(props) => `${props.lineHeight}px`};
	text-decoration: ${(props) => props.textDecoration};
	cursor: ${(props) => props.cursor};

	${(props) => props.textTransform && `
		text-transform: ${props.textTransform};
	`}

	${(props) => props.textAlign && `
		text-align: ${props.textAlign};
	`}

	${(props) => props.padding && `
		padding: ${props.padding};
	`}

	${(props) => props.borderColor && `
		border: 1px solid;
		border-color: ${props.theme[props.borderColor] || props.borderColor};
	`}

	${(props) => props.borderWidth && `
		border-width: ${props.borderWidth};
	`}

	@media screen and (max-width:576px){
        font-size: ${(props) => props.size-props.size*10% + 'px'};
		line-height: ${(props) => props.lineHeight-props.lineHeight*10% + 'px'};
    }
`;

function Ptext({
	marginTop = 10,
	size = 24,
	fontWeight = '600',
	fontFamily = 'Manrope',
	marginBottom = 0,
	lineHeight = 24,
	textAlign='',
	...props
}) {
	return (
		<Wrapper
			marginTop={marginTop}
			size={size}
			fontWeight={fontWeight}
			fontFamily={fontFamily}
			marginBottom={marginBottom}
			lineHeight={lineHeight}
			{...props}
		>
			{props.children}
		</Wrapper>
	);
}

Ptext.propTypes = {
	marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	subSize: PropTypes.string,
	subMarginTop: PropTypes.string,
	fontWeight: PropTypes.any,
	fontFamily: PropTypes.string,
};

export default Ptext;
