import axios from "axios";
import env from 'utils/locale/env.json'
import { getToken } from "./common";

export const postMethod = async (url, body) => {
    try {
        const apiUrl = env.baseUrl + url;

        let data = await axios({
            method: "POST",
            url: apiUrl,
            headers: {
                Accept: "application/json",
            },
            data: body,
        });


        return data;
    } catch (error) {
        return error.response;
    }
}
export const postMethodWithToken = async(url , body)=>{
    try {
        const apiUrl = env.baseUrl + url;

        let data = await axios({
            method: "POST",
            url: apiUrl,
            headers: {
                Accept: "application/json",
                Authorization: "token " + getToken(),
            },
            data: body,
        });
        return data;
    } 
    catch (error) {
        return error.response;
    }
}
export const getMethod = async (url) => {
    try {
        let data = await axios({
            method: "GET",
            url: env.baseUrl+url,
            headers: {
                Accept: "application/json",
            },
        });
        return data;
    } catch (error) {
        return error.response;
    }
}
export const getMethodWithToken = async (url) => {
    let token = getToken()
    try {
        let data = await axios({
            method: "GET",
            url: env.baseUrl+url,
            headers: {
                Accept: "application/json",
                Authorization: "token " + token,
            },
        });
        return data;
    } catch (error) {
        return error.response;
    }
}

export const deleteMethodWithToken = async(url)=>{
    let token = getToken()
    try {
        let data = await axios({
            method:"DELETE",
            url:url,
            headers: {
                Accept: "application/json",
                Authorization: "token " + token,
            },
        });
        return data;
    } catch(error){
        return error.response
    }
}

export const updateMethodWithToken = async(url,body)=>{
    let token = getToken()
    try {
        let data = await axios({
            method:"PATCH",
            url:env.baseUrl+url,
            headers: {
                Accept: "application/json",
                Authorization: "token " + token,
            },
            data: body,
        });
        return data;
    } catch(error){
        return error.response
    }
}
export const stripePostMethod=async(url,body)=>{
    try {
        let data = await axios({
            method:'POST',
            url:url,
            headers:{
                Accept: "application/json",
            },
            data:body,
        });
        return data;
    } catch(error){
        return error.response
    }
}