import { CHECKOUT, CHECKOUT_ERROR, CHECKOUT_SUCCESS } from "./constant"


const checkoutReducer = (state={},action)=>{

    switch(action.type){
        case CHECKOUT:{
            return {
                ...state,
                loading:true,
                error:''
            };
        }
        case CHECKOUT_ERROR:{
            return {
                ...state,
                loading:false,
                type:CHECKOUT_ERROR,
                error:action.payload
            };
        }
        case CHECKOUT_SUCCESS:{
            return {
                ...state,
                type:CHECKOUT_SUCCESS,
                loading:false,
                payload:action.payload,
                error:''
            };
        }
        default :
        return state;
    }
}
export default checkoutReducer;