import { takeLatest,put } from "redux-saga/effects";
import { getMethodWithToken } from "services/apiServices";
import {CART , CART_ERROR , CART_SUCCESS} from './constant';
import { ClearTokenRedirectLogin } from "context/context";

function* getCartApi(action) {

   
    try {
        const response = yield getMethodWithToken(action?.external?.url);
        if(response?.status === 200 || response?.status === 201){
            yield put({type:CART_SUCCESS , payload : response});
            
            if (action.external && action.external.run === true) {
                action.external.handleFunction(response);
            }
        }
        else {
            yield put({type:CART_ERROR , payload : response});
            if(response?.status === 401 || response?.status === 403){
                ClearTokenRedirectLogin(action.external.navigate)
            }
            else if (action.external && action.external.toast) {
                action.external.handleToastError(response)
            }
            action.external.handleError(response);
        }
    }
    catch(error){
        yield put({type:CART_ERROR,payload:error})
    }
}

function* cartSaga(){
    yield takeLatest(CART , getCartApi)
}

export default cartSaga
